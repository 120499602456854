import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box, CardActionArea } from '@mui/material';
import * as serviceIPC from '../../services/service-ipc';
import CustomUseMutation from '../../hooks/customUseMutation';
import configuration from '../../config/ipc-config';
import { toast } from 'react-toastify';

export default function PartnerCard({ partner, refetch }: any) {

  const SuccessCreate = () => {
    toast.success('Partner deleted Successfully');
    refetch()
  }
  const partnerAPI = new serviceIPC.PartnerLogosApi(configuration);
  const { isLoading, mutate } = CustomUseMutation(
    (d: any) => partnerAPI.partnerLogoControllerDeletePartnerLogo(d),
    'deletePartner',
    SuccessCreate);

  const onDelete = async () => {
    mutate(partner?.id ?? 'test')
  }
  return (
    <Card sx={{ height: 290 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={partner?.url}
          alt={partner?.name}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {partner?.name}
          </Typography>

          <Box sx={{ height: 25 }}>
            <Typography variant="body2" color="text.secondary">
              {partner?.description?.length > 50 ? partner?.description.substring(0, 50) : partner?.description}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Box sx={{ textAlign: 'right', width: '100%' }}>
          <Button disabled={isLoading} onClick={onDelete} size="small" variant='contained' color="error">
            Delete
          </Button>
        </Box>
      </CardActions>
    </Card>
  );
}