import axiosConfig from '../config/axios-config';

export const getUrl = ( file : any) => {
    if (file) {
        return URL.createObjectURL(file)
    }
    return null
}


export const uploadFile = async (file: any) => {
    try {
        const f = new FormData();
        f.append('image', file);

        return (await axiosConfig.post('/files/upload', f , {headers : {
                'Content-Type' : 'multipart/form-data',
            }})).data?.data?.link
    }catch (e){
        return null
    }

}