import {Box, Dialog, DialogContent, DialogProps, DialogTitle} from '@mui/material';
import React from 'react';
import TextField from '@mui/material/TextField';
import * as yup from 'yup';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {LoadingButton} from '@mui/lab';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimeField } from '@mui/x-date-pickers/DateTimeField';
import * as serviceIPC from '../../../services/service-ipc';
import configuration from '../../../config/ipc-config';
import CustomUseMutation from '../../../hooks/customUseMutation';

const CreateStepFormDialog: React.FC<DialogProps> = ({title, ...props}) => {
    const nav = useNavigate();
    const SuccessCreate = () => {
        toast.success('Step Created Successfully');
        nav(0)
    }
    const stepAPI = new serviceIPC.StepsApi(configuration);
    const {data  , isLoading , mutate} = CustomUseMutation(
        (d : any)=>stepAPI.stepControllerCreate(d) ,
        'createStep' ,
        SuccessCreate);

    const schemaLogin = yup.object({
        name: yup.string().required('name is required'),
        price: yup.number().required('price is required'),
        description: yup.string(),
        end_date: yup.string().required(),
        total: yup.number().required(),
    }).required();

    const { control ,register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schemaLogin),
    });


    return (
        <>
            <Dialog {...props}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                        <Box component='form' onSubmit={handleSubmit((d : any) => mutate(d))} sx={{mt: 1}}>
                        <TextField
                          margin='normal'
                          required
                          fullWidth
                          error={!!errors.name?.message}
                          {...register('name')}
                          label='Name'
                          autoFocus
                        />
                        <TextField
                          margin='normal'
                          required
                          fullWidth
                          error={!!errors.price?.message}
                          {...register('price')}
                          label='Price'
                          type={'number'}
                          autoFocus
                        />
                        <TextField
                          margin='normal'
                          fullWidth
                          error={!!errors.description?.message}
                          {...register('description')}
                          label='Description'
                          multiline
                          rows={4}
                          autoFocus
                        />
                        <TextField
                          margin='normal'
                          required
                          fullWidth
                          error={!!errors.total?.message}
                          {...register('total')}
                          label='Max Candidate'
                          type={'number'}
                          autoFocus
                        />
                        <Controller
                          name="end_date"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => {
                              return (
                                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                                      <DateTimeField
                                        fullWidth
                                        margin='normal'
                                        label="Expire Date"
                                        format="L hh:mm a"
                                        {...field}
                                      />
                                  </LocalizationProvider>
                                  )
                          }}
                        />
                        <LoadingButton
                          type='submit'
                          fullWidth
                          variant='contained'
                          loading={isLoading}
                          className={'app-btn'}
                          sx={{
                                padding: '0.9rem 0',
                                marginTop: '20px',
                            }}
                        >
                            CREATE
                        </LoadingButton>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}
export default CreateStepFormDialog;