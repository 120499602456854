import {Avatar, IconButton, Stack} from '@mui/material';
import av from './../../../assets/images/fd01.jpg';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useModal } from 'mui-modal-provider';
import SetConfigFormDialog from '../../../components/forms/config-form';

export function AccountMenu () : any {
    const { showModal } = useModal();
    return(
        <>
            <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={2}>
                <IconButton
                  onClick={() => showModal(SetConfigFormDialog, { title: 'Event configuration' })}
                >
                    <SettingsOutlinedIcon fontSize='large' sx={{ color: '#fff', cursor: 'pointer' }} />
                </IconButton>
                <Avatar sx={{ width: 50, height: 50 }} alt="Remy Sharp" src={av}/>
                <Stack>
                    <h2 style={{ fontSize : '1.1em'}}>Administrator</h2>
                    <p>IPC</p>
                </Stack>
            </Stack>
        </>
    )
}