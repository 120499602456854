import { useQuery } from 'react-query';
import { toast } from 'react-toastify';

export default function UseListFetch(callBack : any, identity : any) {
    return useQuery(identity, () =>
            callBack().then((res : any) => res.data.data)
        , {
            onError: function (data) {
                toast.error('an error has occurred', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
            },
            staleTime : 1,
        });
}