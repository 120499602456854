import React from 'react';
import {Stack} from '@mui/material';
import BreadcrumdCustom from '../breadcrumds/breadcrumd-custom';
import styles from './index.module.scss';

function Breadcrumds(props : {name : string , link : string , subname : string}) {
    return (
        <>
           <Stack spacing={3} marginTop={5}>
               <h2 className={styles.title}>{props.name}</h2>
               <BreadcrumdCustom name={props.subname} link={props.link}/>
           </Stack>
        </>
    );
}

export default Breadcrumds;