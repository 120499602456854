export function VerifyAuth() {

    const parseJwt = (token : string) => {
        try {
            return JSON.parse(window.atob(token.split('.')[1]));
        } catch (e) {
            return null;
        }
    };

    const token = localStorage.getItem('token');

    if (token) {
        const decodedJwt = parseJwt(token);
        return decodedJwt.exp * 1000 >= Date.now();
    }

    localStorage.clear();
    return false;

}