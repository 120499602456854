import axiosConfig from '../config/axios-config';

class AuthService {
    async login(credential: { email: string, password: string }) {
        return axiosConfig.post(
            '/auth/login/',
            {email: credential.email, password: credential.password});
    }

}

export default new AuthService();