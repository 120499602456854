import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import logo from './../../../assets/images/Logo-noir.png'
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {useMutation} from 'react-query';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Link, useNavigate} from 'react-router-dom';
import AuthService from '../../../services/auth.service';
import { LoadingButton } from '@mui/lab';



function LoginForm() {

    const nav = useNavigate();

    const schemaLogin = yup.object({
        email: yup.string().email().required('email is required'),
        password: yup.string().required('password is required'),
    }).required();

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schemaLogin),
    });

    const toLogin = useMutation((account : any) => {
        return AuthService.login(account)
    }, {
        onSuccess: async (data, variables, context) => {
            toast.success('Login successful');
            localStorage.setItem('token', data.data.data.token);
            setTimeout(() => {
                nav(0)
            }, 300);
        },
        onError: async (error : any, variables, context) => {
            console.log('errror')
            toast.error(error['message']);
        },
    });

    // submit
    const onSubmit = (data: any) => {
        toLogin.mutate(data);
    }


    return (
        <>
            <Box padding={5}>
                <img src={logo}/>
            </Box>
            <Box component='form' onSubmit={handleSubmit(onSubmit)} sx={{mt: 1}}>
                <TextField
                  margin='normal'
                  required
                  fullWidth
                  error={!!errors.email?.message}
                  {...register('email')}
                  id='email'
                  label='Email Address'
                  autoComplete='email'
                  autoFocus
                  type={'email'}
                />
                <TextField
                  error={!!errors.password?.message}
                  {...register('password')}
                  margin='normal'
                  required
                  fullWidth
                  label='Password'
                  type='password'
                  id='password'
                  autoComplete='current-password'
                />
                {/*<FormControlLabel*/}
                {/*  control={<Checkbox value='remember' color='primary'/>}*/}
                {/*  label='Remember me'*/}
                {/*/>*/}
                <LoadingButton
                  type='submit'
                  fullWidth
                  variant='contained'
                  loading={toLogin.isLoading}
                  className={'app-btn'}
                  sx={{
                        padding: '0.9rem 0',
                        marginTop: '20px',
                    }}
                >
                    Sign In
                </LoadingButton>
            </Box>
        </>
    );
}

export default LoginForm;