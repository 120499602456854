import {Box, Stack, Tab} from '@mui/material';
import Breadcrumds from '../../components/breadcrumds';
import React, {useEffect} from 'react';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {UseCrudRequest} from '../../hooks/useCrudRequest';
import UseListFetch from '../../hooks/useListFetch';
import ListCandidature from './listCandidature';
import NewCandidate from '../../components/forms/candidature-form/new-candidate';


function Candidates() {
    // steps
    const {list} = UseCrudRequest('steps');
    const { data} = UseListFetch(list, 'listStep');
    const [value, setValue] = React.useState('2');
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    useEffect(()=>{
        data?.length ? setValue(data[0].id) : null;
    },[data])

    return (
        <>
            <Stack direction='row' justifyContent={'space-between'} alignItems={'center'}>
                <Breadcrumds name={'Candidates'} link={'#'} subname={'candidates'}/>
                <NewCandidate/>
            </Stack>
            <Stack sx={{
                minHeight: '65vh',
                width: '100%',
                border: '2px solid #ECEEF7',
                backgroundColor: '#FFFFFF',
                borderRadius: '6px',
                padding: 2,
                marginTop: '5%',
            }}>
                <TabContext value={value}>
                    <Box sx={{borderBottom: 2, borderColor: 'divider'}}>
                        <TabList onChange={handleChange} aria-label="List Steps">
                            {data?.map((d: any , k : number) => {
                                return (<Tab label={d?.name} value={d?.id} key={k}/>)
                            })}
                        </TabList>
                    </Box>
                    {data?.map((d: any , k : number) => {
                        return (
                            <TabPanel value={d?.id} key={k}>
                                <ListCandidature id={d?.id}/>
                            </TabPanel>
                        )
                    })}
                </TabContext>
            </Stack>
        </>
    );
}

// Default export
export default Candidates;
