import * as serviceIPC from './../services/service-ipc';

const token = localStorage.getItem('token');
const configuration = new serviceIPC.Configuration(
    {
        basePath : 'https://api.iceberg-picture-challenges.com' ,
        // basePath : 'http://localhost:4000' ,
        accessToken : token ?? '',
    });

export default configuration;
