import { createTheme } from '@mui/material/styles';

// A custom theme for this app-form
const theme = createTheme({
    palette: {
        primary: {
            main: '#644b0d',
        },
        secondary: {
            main: '#19857b',
        },
        error: {
            // main: red.A400,
            main: '#F75676',
        },
    },
});

export default theme;
