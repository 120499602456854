/* tslint:disable */
/* eslint-disable */
/**
 * IPC service
 * Documentation for IPC API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CreateCandidateDto
 */
export interface CreateCandidateDto {
    /**
     * 
     * @type {string}
     * @memberof CreateCandidateDto
     */
    'phone': string;
    /**
     * 
     * @type {CreateCandidateProfileDto}
     * @memberof CreateCandidateDto
     */
    'profile': CreateCandidateProfileDto;
    /**
     * 
     * @type {CreateCandidatureDto}
     * @memberof CreateCandidateDto
     */
    'candidature': CreateCandidatureDto;
}
/**
 * 
 * @export
 * @interface CreateCandidateProfileDto
 */
export interface CreateCandidateProfileDto {
    /**
     * 
     * @type {string}
     * @memberof CreateCandidateProfileDto
     */
    'full_name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCandidateProfileDto
     */
    'sex': CreateCandidateProfileDtoSexEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateCandidateProfileDto
     */
    'ville': string;
}

export const CreateCandidateProfileDtoSexEnum = {
    Male: 'MALE',
    Female: 'FEMALE',
    Unknown: 'UNKNOWN'
} as const;

export type CreateCandidateProfileDtoSexEnum = typeof CreateCandidateProfileDtoSexEnum[keyof typeof CreateCandidateProfileDtoSexEnum];

/**
 * 
 * @export
 * @interface CreateCandidatureDto
 */
export interface CreateCandidatureDto {
    /**
     * 
     * @type {string}
     * @memberof CreateCandidatureDto
     */
    'profile': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCandidatureDto
     */
    'shared_profile'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCandidatureDto
     */
    'presentation': string;
}
/**
 * 
 * @export
 * @interface CreatePartnerLogoDto
 */
export interface CreatePartnerLogoDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePartnerLogoDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePartnerLogoDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePartnerLogoDto
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface CreateStepDto
 */
export interface CreateStepDto {
    /**
     * 
     * @type {string}
     * @memberof CreateStepDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreateStepDto
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof CreateStepDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof CreateStepDto
     */
    'end_date': number;
    /**
     * 
     * @type {string}
     * @memberof CreateStepDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface ErrorDto
 */
export interface ErrorDto {
    /**
     * 
     * @type {string}
     * @memberof ErrorDto
     */
    'error'?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorDto
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof ErrorDto
     */
    'statusCode': number;
}
/**
 * 
 * @export
 * @interface LoginDto
 */
export interface LoginDto {
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface OptDto
 */
export interface OptDto {
    /**
     * 
     * @type {string}
     * @memberof OptDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface PaymentDto
 */
export interface PaymentDto {
    /**
     * 
     * @type {string}
     * @memberof PaymentDto
     */
    'pay_number': string;
}
/**
 * 
 * @export
 * @interface RegisterDto
 */
export interface RegisterDto {
    /**
     * 
     * @type {string}
     * @memberof RegisterDto
     */
    'phone': string;
    /**
     * 
     * @type {CreateCandidateProfileDto}
     * @memberof RegisterDto
     */
    'profile': CreateCandidateProfileDto;
    /**
     * 
     * @type {CreateCandidatureDto}
     * @memberof RegisterDto
     */
    'candidature': CreateCandidatureDto;
    /**
     * 
     * @type {PaymentDto}
     * @memberof RegisterDto
     */
    'payment': PaymentDto;
}
/**
 * 
 * @export
 * @interface SuccessResponseDto
 */
export interface SuccessResponseDto {
    /**
     * 
     * @type {string}
     * @memberof SuccessResponseDto
     */
    'data': string;
    /**
     * 
     * @type {string}
     * @memberof SuccessResponseDto
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof SuccessResponseDto
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface UpdateCandidateProfileDto
 */
export interface UpdateCandidateProfileDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateCandidateProfileDto
     */
    'full_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCandidateProfileDto
     */
    'sex'?: UpdateCandidateProfileDtoSexEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateCandidateProfileDto
     */
    'ville'?: string;
}

export const UpdateCandidateProfileDtoSexEnum = {
    Male: 'MALE',
    Female: 'FEMALE',
    Unknown: 'UNKNOWN'
} as const;

export type UpdateCandidateProfileDtoSexEnum = typeof UpdateCandidateProfileDtoSexEnum[keyof typeof UpdateCandidateProfileDtoSexEnum];

/**
 * 
 * @export
 * @interface UpdateCandidatureDto
 */
export interface UpdateCandidatureDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateCandidatureDto
     */
    'profile'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCandidatureDto
     */
    'shared_profile'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCandidatureDto
     */
    'presentation'?: string;
}
/**
 * 
 * @export
 * @interface UpdateConfigurationDto
 */
export interface UpdateConfigurationDto {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConfigurationDto
     */
    'online'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConfigurationDto
     */
    'free'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConfigurationDto
     */
    'sms'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdatePartnerLogoDto
 */
export interface UpdatePartnerLogoDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePartnerLogoDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePartnerLogoDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePartnerLogoDto
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface UpdateStepDto
 */
export interface UpdateStepDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateStepDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateStepDto
     */
    'price'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateStepDto
     */
    'total'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateStepDto
     */
    'active'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateStepDto
     */
    'vote'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateStepDto
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateStepDto
     */
    'end_date': number;
}
/**
 * 
 * @export
 * @interface VoteDto
 */
export interface VoteDto {
    /**
     * 
     * @type {string}
     * @memberof VoteDto
     */
    'pay_number': string;
    /**
     * 
     * @type {number}
     * @memberof VoteDto
     */
    'factor': number;
}

/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary users authentication
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogin: async (loginDto: LoginDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginDto' is not null or undefined
            assertParamExists('authControllerLogin', 'loginDto', loginDto)
            const localVarPath = `/api/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary users authentication
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerLogin(loginDto: LoginDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerLogin(loginDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * 
         * @summary users authentication
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogin(loginDto: LoginDto, options?: any): AxiosPromise<void> {
            return localVarFp.authControllerLogin(loginDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * 
     * @summary users authentication
     * @param {LoginDto} loginDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authControllerLogin(loginDto: LoginDto, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authControllerLogin(loginDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CandidatesApi - axios parameter creator
 * @export
 */
export const CandidatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary admin get change level candidate
         * @param {number} candidateId 
         * @param {number} stepId 
         * @param {'ADD' | 'REMOVE'} action 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        candidateControllerAddCandidateLevel: async (candidateId: number, stepId: number, action: 'ADD' | 'REMOVE', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'candidateId' is not null or undefined
            assertParamExists('candidateControllerAddCandidateLevel', 'candidateId', candidateId)
            // verify required parameter 'stepId' is not null or undefined
            assertParamExists('candidateControllerAddCandidateLevel', 'stepId', stepId)
            // verify required parameter 'action' is not null or undefined
            assertParamExists('candidateControllerAddCandidateLevel', 'action', action)
            const localVarPath = `/api/candidates/{stepId}/{candidateId}`
                .replace(`{${"candidateId"}}`, encodeURIComponent(String(candidateId)))
                .replace(`{${"stepId"}}`, encodeURIComponent(String(stepId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (action !== undefined) {
                localVarQueryParameter['action'] = action;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary admin get candidate information
         * @param {string} phone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        candidateControllerCheckCandidateByCNI: async (phone: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'phone' is not null or undefined
            assertParamExists('candidateControllerCheckCandidateByCNI', 'phone', phone)
            const localVarPath = `/api/candidates/check/{phone}`
                .replace(`{${"phone"}}`, encodeURIComponent(String(phone)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary admin create user account 
         * @param {CreateCandidateDto} createCandidateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        candidateControllerCreateCandidate: async (createCandidateDto: CreateCandidateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCandidateDto' is not null or undefined
            assertParamExists('candidateControllerCreateCandidate', 'createCandidateDto', createCandidateDto)
            const localVarPath = `/api/candidates/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCandidateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary admin delete a candidate
         * @param {number} candidateId candidate ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        candidateControllerDeleteById: async (candidateId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'candidateId' is not null or undefined
            assertParamExists('candidateControllerDeleteById', 'candidateId', candidateId)
            const localVarPath = `/api/candidates/{candidateId}`
                .replace(`{${"candidateId"}}`, encodeURIComponent(String(candidateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary admin get candidate information
         * @param {number} candidateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        candidateControllerGetCandidateById: async (candidateId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'candidateId' is not null or undefined
            assertParamExists('candidateControllerGetCandidateById', 'candidateId', candidateId)
            const localVarPath = `/api/candidates/details/{candidateId}`
                .replace(`{${"candidateId"}}`, encodeURIComponent(String(candidateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary admin get list candidate accounts
         * @param {number} [page] Number of page to get and default value is 0
         * @param {number} [limit] Number of items per page and default is 0. Max is 100
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        candidateControllerListSteps: async (page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/candidates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary users valid registration
         * @param {OptDto} optDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        candidateControllerOptCandidate: async (optDto: OptDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'optDto' is not null or undefined
            assertParamExists('candidateControllerOptCandidate', 'optDto', optDto)
            const localVarPath = `/api/candidates/verify-opt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(optDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary users create account
         * @param {RegisterDto} registerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        candidateControllerRegisterCandidate: async (registerDto: RegisterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerDto' is not null or undefined
            assertParamExists('candidateControllerRegisterCandidate', 'registerDto', registerDto)
            const localVarPath = `/api/candidates/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary admin update candidate
         * @param {number} candidateId candidate ID
         * @param {UpdateCandidateProfileDto} updateCandidateProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        candidateControllerUpdateLevel: async (candidateId: number, updateCandidateProfileDto: UpdateCandidateProfileDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'candidateId' is not null or undefined
            assertParamExists('candidateControllerUpdateLevel', 'candidateId', candidateId)
            // verify required parameter 'updateCandidateProfileDto' is not null or undefined
            assertParamExists('candidateControllerUpdateLevel', 'updateCandidateProfileDto', updateCandidateProfileDto)
            const localVarPath = `/api/candidates/update/{candidateId}`
                .replace(`{${"candidateId"}}`, encodeURIComponent(String(candidateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCandidateProfileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CandidatesApi - functional programming interface
 * @export
 */
export const CandidatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CandidatesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary admin get change level candidate
         * @param {number} candidateId 
         * @param {number} stepId 
         * @param {'ADD' | 'REMOVE'} action 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async candidateControllerAddCandidateLevel(candidateId: number, stepId: number, action: 'ADD' | 'REMOVE', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.candidateControllerAddCandidateLevel(candidateId, stepId, action, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary admin get candidate information
         * @param {string} phone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async candidateControllerCheckCandidateByCNI(phone: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.candidateControllerCheckCandidateByCNI(phone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary admin create user account 
         * @param {CreateCandidateDto} createCandidateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async candidateControllerCreateCandidate(createCandidateDto: CreateCandidateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.candidateControllerCreateCandidate(createCandidateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary admin delete a candidate
         * @param {number} candidateId candidate ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async candidateControllerDeleteById(candidateId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.candidateControllerDeleteById(candidateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary admin get candidate information
         * @param {number} candidateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async candidateControllerGetCandidateById(candidateId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.candidateControllerGetCandidateById(candidateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary admin get list candidate accounts
         * @param {number} [page] Number of page to get and default value is 0
         * @param {number} [limit] Number of items per page and default is 0. Max is 100
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async candidateControllerListSteps(page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.candidateControllerListSteps(page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary users valid registration
         * @param {OptDto} optDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async candidateControllerOptCandidate(optDto: OptDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.candidateControllerOptCandidate(optDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary users create account
         * @param {RegisterDto} registerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async candidateControllerRegisterCandidate(registerDto: RegisterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.candidateControllerRegisterCandidate(registerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary admin update candidate
         * @param {number} candidateId candidate ID
         * @param {UpdateCandidateProfileDto} updateCandidateProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async candidateControllerUpdateLevel(candidateId: number, updateCandidateProfileDto: UpdateCandidateProfileDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.candidateControllerUpdateLevel(candidateId, updateCandidateProfileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CandidatesApi - factory interface
 * @export
 */
export const CandidatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CandidatesApiFp(configuration)
    return {
        /**
         * 
         * @summary admin get change level candidate
         * @param {number} candidateId 
         * @param {number} stepId 
         * @param {'ADD' | 'REMOVE'} action 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        candidateControllerAddCandidateLevel(candidateId: number, stepId: number, action: 'ADD' | 'REMOVE', options?: any): AxiosPromise<void> {
            return localVarFp.candidateControllerAddCandidateLevel(candidateId, stepId, action, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary admin get candidate information
         * @param {string} phone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        candidateControllerCheckCandidateByCNI(phone: string, options?: any): AxiosPromise<void> {
            return localVarFp.candidateControllerCheckCandidateByCNI(phone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary admin create user account 
         * @param {CreateCandidateDto} createCandidateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        candidateControllerCreateCandidate(createCandidateDto: CreateCandidateDto, options?: any): AxiosPromise<void> {
            return localVarFp.candidateControllerCreateCandidate(createCandidateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary admin delete a candidate
         * @param {number} candidateId candidate ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        candidateControllerDeleteById(candidateId: number, options?: any): AxiosPromise<SuccessResponseDto> {
            return localVarFp.candidateControllerDeleteById(candidateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary admin get candidate information
         * @param {number} candidateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        candidateControllerGetCandidateById(candidateId: number, options?: any): AxiosPromise<void> {
            return localVarFp.candidateControllerGetCandidateById(candidateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary admin get list candidate accounts
         * @param {number} [page] Number of page to get and default value is 0
         * @param {number} [limit] Number of items per page and default is 0. Max is 100
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        candidateControllerListSteps(page?: number, limit?: number, options?: any): AxiosPromise<SuccessResponseDto> {
            return localVarFp.candidateControllerListSteps(page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary users valid registration
         * @param {OptDto} optDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        candidateControllerOptCandidate(optDto: OptDto, options?: any): AxiosPromise<void> {
            return localVarFp.candidateControllerOptCandidate(optDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary users create account
         * @param {RegisterDto} registerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        candidateControllerRegisterCandidate(registerDto: RegisterDto, options?: any): AxiosPromise<void> {
            return localVarFp.candidateControllerRegisterCandidate(registerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary admin update candidate
         * @param {number} candidateId candidate ID
         * @param {UpdateCandidateProfileDto} updateCandidateProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        candidateControllerUpdateLevel(candidateId: number, updateCandidateProfileDto: UpdateCandidateProfileDto, options?: any): AxiosPromise<void> {
            return localVarFp.candidateControllerUpdateLevel(candidateId, updateCandidateProfileDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CandidatesApi - object-oriented interface
 * @export
 * @class CandidatesApi
 * @extends {BaseAPI}
 */
export class CandidatesApi extends BaseAPI {
    /**
     * 
     * @summary admin get change level candidate
     * @param {number} candidateId 
     * @param {number} stepId 
     * @param {'ADD' | 'REMOVE'} action 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidatesApi
     */
    public candidateControllerAddCandidateLevel(candidateId: number, stepId: number, action: 'ADD' | 'REMOVE', options?: AxiosRequestConfig) {
        return CandidatesApiFp(this.configuration).candidateControllerAddCandidateLevel(candidateId, stepId, action, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary admin get candidate information
     * @param {string} phone 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidatesApi
     */
    public candidateControllerCheckCandidateByCNI(phone: string, options?: AxiosRequestConfig) {
        return CandidatesApiFp(this.configuration).candidateControllerCheckCandidateByCNI(phone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary admin create user account 
     * @param {CreateCandidateDto} createCandidateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidatesApi
     */
    public candidateControllerCreateCandidate(createCandidateDto: CreateCandidateDto, options?: AxiosRequestConfig) {
        return CandidatesApiFp(this.configuration).candidateControllerCreateCandidate(createCandidateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary admin delete a candidate
     * @param {number} candidateId candidate ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidatesApi
     */
    public candidateControllerDeleteById(candidateId: number, options?: AxiosRequestConfig) {
        return CandidatesApiFp(this.configuration).candidateControllerDeleteById(candidateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary admin get candidate information
     * @param {number} candidateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidatesApi
     */
    public candidateControllerGetCandidateById(candidateId: number, options?: AxiosRequestConfig) {
        return CandidatesApiFp(this.configuration).candidateControllerGetCandidateById(candidateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary admin get list candidate accounts
     * @param {number} [page] Number of page to get and default value is 0
     * @param {number} [limit] Number of items per page and default is 0. Max is 100
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidatesApi
     */
    public candidateControllerListSteps(page?: number, limit?: number, options?: AxiosRequestConfig) {
        return CandidatesApiFp(this.configuration).candidateControllerListSteps(page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary users valid registration
     * @param {OptDto} optDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidatesApi
     */
    public candidateControllerOptCandidate(optDto: OptDto, options?: AxiosRequestConfig) {
        return CandidatesApiFp(this.configuration).candidateControllerOptCandidate(optDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary users create account
     * @param {RegisterDto} registerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidatesApi
     */
    public candidateControllerRegisterCandidate(registerDto: RegisterDto, options?: AxiosRequestConfig) {
        return CandidatesApiFp(this.configuration).candidateControllerRegisterCandidate(registerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary admin update candidate
     * @param {number} candidateId candidate ID
     * @param {UpdateCandidateProfileDto} updateCandidateProfileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidatesApi
     */
    public candidateControllerUpdateLevel(candidateId: number, updateCandidateProfileDto: UpdateCandidateProfileDto, options?: AxiosRequestConfig) {
        return CandidatesApiFp(this.configuration).candidateControllerUpdateLevel(candidateId, updateCandidateProfileDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CandidaturesApi - axios parameter creator
 * @export
 */
export const CandidaturesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary admin add new candidature for candidate
         * @param {number} candidateId 
         * @param {number} stepId 
         * @param {CreateCandidatureDto} createCandidatureDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        candidatureControllerAdminAddCandidature: async (candidateId: number, stepId: number, createCandidatureDto: CreateCandidatureDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'candidateId' is not null or undefined
            assertParamExists('candidatureControllerAdminAddCandidature', 'candidateId', candidateId)
            // verify required parameter 'stepId' is not null or undefined
            assertParamExists('candidatureControllerAdminAddCandidature', 'stepId', stepId)
            // verify required parameter 'createCandidatureDto' is not null or undefined
            assertParamExists('candidatureControllerAdminAddCandidature', 'createCandidatureDto', createCandidatureDto)
            const localVarPath = `/api/candidatures/step/{stepId}/{candidateId}`
                .replace(`{${"candidateId"}}`, encodeURIComponent(String(candidateId)))
                .replace(`{${"stepId"}}`, encodeURIComponent(String(stepId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCandidatureDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary admin update candidature for candidate
         * @param {number} candidateId 
         * @param {number} stepId 
         * @param {UpdateCandidatureDto} updateCandidatureDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        candidatureControllerAdminUpdateCandidature: async (candidateId: number, stepId: number, updateCandidatureDto: UpdateCandidatureDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'candidateId' is not null or undefined
            assertParamExists('candidatureControllerAdminUpdateCandidature', 'candidateId', candidateId)
            // verify required parameter 'stepId' is not null or undefined
            assertParamExists('candidatureControllerAdminUpdateCandidature', 'stepId', stepId)
            // verify required parameter 'updateCandidatureDto' is not null or undefined
            assertParamExists('candidatureControllerAdminUpdateCandidature', 'updateCandidatureDto', updateCandidatureDto)
            const localVarPath = `/api/candidatures/{stepId}/{candidateId}`
                .replace(`{${"candidateId"}}`, encodeURIComponent(String(candidateId)))
                .replace(`{${"stepId"}}`, encodeURIComponent(String(stepId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCandidatureDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary customer vote candidate
         * @param {number} candidateId 
         * @param {number} stepId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        candidatureControllerDeleteCandidature: async (candidateId: number, stepId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'candidateId' is not null or undefined
            assertParamExists('candidatureControllerDeleteCandidature', 'candidateId', candidateId)
            // verify required parameter 'stepId' is not null or undefined
            assertParamExists('candidatureControllerDeleteCandidature', 'stepId', stepId)
            const localVarPath = `/api/candidatures/{stepId}/{candidateId}`
                .replace(`{${"candidateId"}}`, encodeURIComponent(String(candidateId)))
                .replace(`{${"stepId"}}`, encodeURIComponent(String(stepId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get candidature details
         * @param {number} candidateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        candidatureControllerDetailsActiveCandidate: async (candidateId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'candidateId' is not null or undefined
            assertParamExists('candidatureControllerDetailsActiveCandidate', 'candidateId', candidateId)
            const localVarPath = `/api/candidatures/active/details/{candidateId}`
                .replace(`{${"candidateId"}}`, encodeURIComponent(String(candidateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get candidature details
         * @param {number} candidateId 
         * @param {number} stepId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        candidatureControllerDetailsCandidate: async (candidateId: number, stepId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'candidateId' is not null or undefined
            assertParamExists('candidatureControllerDetailsCandidate', 'candidateId', candidateId)
            // verify required parameter 'stepId' is not null or undefined
            assertParamExists('candidatureControllerDetailsCandidate', 'stepId', stepId)
            const localVarPath = `/api/candidatures/details/{stepId}/{candidateId}`
                .replace(`{${"candidateId"}}`, encodeURIComponent(String(candidateId)))
                .replace(`{${"stepId"}}`, encodeURIComponent(String(stepId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary user get list candidature by Step
         * @param {number} stepId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        candidatureControllerListCandidateProfileStep: async (stepId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepId' is not null or undefined
            assertParamExists('candidatureControllerListCandidateProfileStep', 'stepId', stepId)
            const localVarPath = `/api/candidatures/level/{stepId}`
                .replace(`{${"stepId"}}`, encodeURIComponent(String(stepId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary user get list candidature for step active
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        candidatureControllerListCandidateProfileStepActive: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/candidatures/level/active/votes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary admin get list candidature of candidate
         * @param {number} candidateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        candidatureControllerListSteps: async (candidateId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'candidateId' is not null or undefined
            assertParamExists('candidatureControllerListSteps', 'candidateId', candidateId)
            const localVarPath = `/api/candidatures/{candidateId}`
                .replace(`{${"candidateId"}}`, encodeURIComponent(String(candidateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary admin note candidature
         * @param {number} candidateId 
         * @param {number} notes 
         * @param {number} media 
         * @param {number} stepId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        candidatureControllerNoteCandidate: async (candidateId: number, notes: number, media: number, stepId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'candidateId' is not null or undefined
            assertParamExists('candidatureControllerNoteCandidate', 'candidateId', candidateId)
            // verify required parameter 'notes' is not null or undefined
            assertParamExists('candidatureControllerNoteCandidate', 'notes', notes)
            // verify required parameter 'media' is not null or undefined
            assertParamExists('candidatureControllerNoteCandidate', 'media', media)
            // verify required parameter 'stepId' is not null or undefined
            assertParamExists('candidatureControllerNoteCandidate', 'stepId', stepId)
            const localVarPath = `/api/candidatures/notes/{stepId}/{candidateId}/{notes}/{media}`
                .replace(`{${"candidateId"}}`, encodeURIComponent(String(candidateId)))
                .replace(`{${"notes"}}`, encodeURIComponent(String(notes)))
                .replace(`{${"media"}}`, encodeURIComponent(String(media)))
                .replace(`{${"stepId"}}`, encodeURIComponent(String(stepId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary customer vote candidate
         * @param {number} candidateId 
         * @param {VoteDto} voteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        candidatureControllerVoteCandidate: async (candidateId: number, voteDto: VoteDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'candidateId' is not null or undefined
            assertParamExists('candidatureControllerVoteCandidate', 'candidateId', candidateId)
            // verify required parameter 'voteDto' is not null or undefined
            assertParamExists('candidatureControllerVoteCandidate', 'voteDto', voteDto)
            const localVarPath = `/api/candidatures/vote/{candidateId}`
                .replace(`{${"candidateId"}}`, encodeURIComponent(String(candidateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(voteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CandidaturesApi - functional programming interface
 * @export
 */
export const CandidaturesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CandidaturesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary admin add new candidature for candidate
         * @param {number} candidateId 
         * @param {number} stepId 
         * @param {CreateCandidatureDto} createCandidatureDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async candidatureControllerAdminAddCandidature(candidateId: number, stepId: number, createCandidatureDto: CreateCandidatureDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.candidatureControllerAdminAddCandidature(candidateId, stepId, createCandidatureDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary admin update candidature for candidate
         * @param {number} candidateId 
         * @param {number} stepId 
         * @param {UpdateCandidatureDto} updateCandidatureDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async candidatureControllerAdminUpdateCandidature(candidateId: number, stepId: number, updateCandidatureDto: UpdateCandidatureDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.candidatureControllerAdminUpdateCandidature(candidateId, stepId, updateCandidatureDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary customer vote candidate
         * @param {number} candidateId 
         * @param {number} stepId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async candidatureControllerDeleteCandidature(candidateId: number, stepId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.candidatureControllerDeleteCandidature(candidateId, stepId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get candidature details
         * @param {number} candidateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async candidatureControllerDetailsActiveCandidate(candidateId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.candidatureControllerDetailsActiveCandidate(candidateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get candidature details
         * @param {number} candidateId 
         * @param {number} stepId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async candidatureControllerDetailsCandidate(candidateId: number, stepId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.candidatureControllerDetailsCandidate(candidateId, stepId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary user get list candidature by Step
         * @param {number} stepId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async candidatureControllerListCandidateProfileStep(stepId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.candidatureControllerListCandidateProfileStep(stepId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary user get list candidature for step active
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async candidatureControllerListCandidateProfileStepActive(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.candidatureControllerListCandidateProfileStepActive(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary admin get list candidature of candidate
         * @param {number} candidateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async candidatureControllerListSteps(candidateId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.candidatureControllerListSteps(candidateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary admin note candidature
         * @param {number} candidateId 
         * @param {number} notes 
         * @param {number} media 
         * @param {number} stepId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async candidatureControllerNoteCandidate(candidateId: number, notes: number, media: number, stepId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.candidatureControllerNoteCandidate(candidateId, notes, media, stepId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary customer vote candidate
         * @param {number} candidateId 
         * @param {VoteDto} voteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async candidatureControllerVoteCandidate(candidateId: number, voteDto: VoteDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.candidatureControllerVoteCandidate(candidateId, voteDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CandidaturesApi - factory interface
 * @export
 */
export const CandidaturesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CandidaturesApiFp(configuration)
    return {
        /**
         * 
         * @summary admin add new candidature for candidate
         * @param {number} candidateId 
         * @param {number} stepId 
         * @param {CreateCandidatureDto} createCandidatureDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        candidatureControllerAdminAddCandidature(candidateId: number, stepId: number, createCandidatureDto: CreateCandidatureDto, options?: any): AxiosPromise<void> {
            return localVarFp.candidatureControllerAdminAddCandidature(candidateId, stepId, createCandidatureDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary admin update candidature for candidate
         * @param {number} candidateId 
         * @param {number} stepId 
         * @param {UpdateCandidatureDto} updateCandidatureDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        candidatureControllerAdminUpdateCandidature(candidateId: number, stepId: number, updateCandidatureDto: UpdateCandidatureDto, options?: any): AxiosPromise<void> {
            return localVarFp.candidatureControllerAdminUpdateCandidature(candidateId, stepId, updateCandidatureDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary customer vote candidate
         * @param {number} candidateId 
         * @param {number} stepId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        candidatureControllerDeleteCandidature(candidateId: number, stepId: number, options?: any): AxiosPromise<void> {
            return localVarFp.candidatureControllerDeleteCandidature(candidateId, stepId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get candidature details
         * @param {number} candidateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        candidatureControllerDetailsActiveCandidate(candidateId: number, options?: any): AxiosPromise<void> {
            return localVarFp.candidatureControllerDetailsActiveCandidate(candidateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get candidature details
         * @param {number} candidateId 
         * @param {number} stepId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        candidatureControllerDetailsCandidate(candidateId: number, stepId: number, options?: any): AxiosPromise<void> {
            return localVarFp.candidatureControllerDetailsCandidate(candidateId, stepId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary user get list candidature by Step
         * @param {number} stepId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        candidatureControllerListCandidateProfileStep(stepId: number, options?: any): AxiosPromise<SuccessResponseDto> {
            return localVarFp.candidatureControllerListCandidateProfileStep(stepId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary user get list candidature for step active
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        candidatureControllerListCandidateProfileStepActive(options?: any): AxiosPromise<SuccessResponseDto> {
            return localVarFp.candidatureControllerListCandidateProfileStepActive(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary admin get list candidature of candidate
         * @param {number} candidateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        candidatureControllerListSteps(candidateId: number, options?: any): AxiosPromise<SuccessResponseDto> {
            return localVarFp.candidatureControllerListSteps(candidateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary admin note candidature
         * @param {number} candidateId 
         * @param {number} notes 
         * @param {number} media 
         * @param {number} stepId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        candidatureControllerNoteCandidate(candidateId: number, notes: number, media: number, stepId: number, options?: any): AxiosPromise<void> {
            return localVarFp.candidatureControllerNoteCandidate(candidateId, notes, media, stepId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary customer vote candidate
         * @param {number} candidateId 
         * @param {VoteDto} voteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        candidatureControllerVoteCandidate(candidateId: number, voteDto: VoteDto, options?: any): AxiosPromise<void> {
            return localVarFp.candidatureControllerVoteCandidate(candidateId, voteDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CandidaturesApi - object-oriented interface
 * @export
 * @class CandidaturesApi
 * @extends {BaseAPI}
 */
export class CandidaturesApi extends BaseAPI {
    /**
     * 
     * @summary admin add new candidature for candidate
     * @param {number} candidateId 
     * @param {number} stepId 
     * @param {CreateCandidatureDto} createCandidatureDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidaturesApi
     */
    public candidatureControllerAdminAddCandidature(candidateId: number, stepId: number, createCandidatureDto: CreateCandidatureDto, options?: AxiosRequestConfig) {
        return CandidaturesApiFp(this.configuration).candidatureControllerAdminAddCandidature(candidateId, stepId, createCandidatureDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary admin update candidature for candidate
     * @param {number} candidateId 
     * @param {number} stepId 
     * @param {UpdateCandidatureDto} updateCandidatureDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidaturesApi
     */
    public candidatureControllerAdminUpdateCandidature(candidateId: number, stepId: number, updateCandidatureDto: UpdateCandidatureDto, options?: AxiosRequestConfig) {
        return CandidaturesApiFp(this.configuration).candidatureControllerAdminUpdateCandidature(candidateId, stepId, updateCandidatureDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary customer vote candidate
     * @param {number} candidateId 
     * @param {number} stepId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidaturesApi
     */
    public candidatureControllerDeleteCandidature(candidateId: number, stepId: number, options?: AxiosRequestConfig) {
        return CandidaturesApiFp(this.configuration).candidatureControllerDeleteCandidature(candidateId, stepId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get candidature details
     * @param {number} candidateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidaturesApi
     */
    public candidatureControllerDetailsActiveCandidate(candidateId: number, options?: AxiosRequestConfig) {
        return CandidaturesApiFp(this.configuration).candidatureControllerDetailsActiveCandidate(candidateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get candidature details
     * @param {number} candidateId 
     * @param {number} stepId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidaturesApi
     */
    public candidatureControllerDetailsCandidate(candidateId: number, stepId: number, options?: AxiosRequestConfig) {
        return CandidaturesApiFp(this.configuration).candidatureControllerDetailsCandidate(candidateId, stepId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary user get list candidature by Step
     * @param {number} stepId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidaturesApi
     */
    public candidatureControllerListCandidateProfileStep(stepId: number, options?: AxiosRequestConfig) {
        return CandidaturesApiFp(this.configuration).candidatureControllerListCandidateProfileStep(stepId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary user get list candidature for step active
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidaturesApi
     */
    public candidatureControllerListCandidateProfileStepActive(options?: AxiosRequestConfig) {
        return CandidaturesApiFp(this.configuration).candidatureControllerListCandidateProfileStepActive(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary admin get list candidature of candidate
     * @param {number} candidateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidaturesApi
     */
    public candidatureControllerListSteps(candidateId: number, options?: AxiosRequestConfig) {
        return CandidaturesApiFp(this.configuration).candidatureControllerListSteps(candidateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary admin note candidature
     * @param {number} candidateId 
     * @param {number} notes 
     * @param {number} media 
     * @param {number} stepId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidaturesApi
     */
    public candidatureControllerNoteCandidate(candidateId: number, notes: number, media: number, stepId: number, options?: AxiosRequestConfig) {
        return CandidaturesApiFp(this.configuration).candidatureControllerNoteCandidate(candidateId, notes, media, stepId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary customer vote candidate
     * @param {number} candidateId 
     * @param {VoteDto} voteDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidaturesApi
     */
    public candidatureControllerVoteCandidate(candidateId: number, voteDto: VoteDto, options?: AxiosRequestConfig) {
        return CandidaturesApiFp(this.configuration).candidatureControllerVoteCandidate(candidateId, voteDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConfigurationSignUpApi - axios parameter creator
 * @export
 */
export const ConfigurationSignUpApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Configuration Sign Up
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurationControllerGetConfiguration: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/configuration/sign-up`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update partner logo by id
         * @param {UpdateConfigurationDto} updateConfigurationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurationControllerUpdateConfig: async (updateConfigurationDto: UpdateConfigurationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateConfigurationDto' is not null or undefined
            assertParamExists('configurationControllerUpdateConfig', 'updateConfigurationDto', updateConfigurationDto)
            const localVarPath = `/api/configuration/sign-up`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateConfigurationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigurationSignUpApi - functional programming interface
 * @export
 */
export const ConfigurationSignUpApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConfigurationSignUpApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Configuration Sign Up
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async configurationControllerGetConfiguration(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.configurationControllerGetConfiguration(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update partner logo by id
         * @param {UpdateConfigurationDto} updateConfigurationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async configurationControllerUpdateConfig(updateConfigurationDto: UpdateConfigurationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.configurationControllerUpdateConfig(updateConfigurationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConfigurationSignUpApi - factory interface
 * @export
 */
export const ConfigurationSignUpApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConfigurationSignUpApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Configuration Sign Up
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurationControllerGetConfiguration(options?: any): AxiosPromise<void> {
            return localVarFp.configurationControllerGetConfiguration(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update partner logo by id
         * @param {UpdateConfigurationDto} updateConfigurationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurationControllerUpdateConfig(updateConfigurationDto: UpdateConfigurationDto, options?: any): AxiosPromise<void> {
            return localVarFp.configurationControllerUpdateConfig(updateConfigurationDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConfigurationSignUpApi - object-oriented interface
 * @export
 * @class ConfigurationSignUpApi
 * @extends {BaseAPI}
 */
export class ConfigurationSignUpApi extends BaseAPI {
    /**
     * 
     * @summary Get Configuration Sign Up
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationSignUpApi
     */
    public configurationControllerGetConfiguration(options?: AxiosRequestConfig) {
        return ConfigurationSignUpApiFp(this.configuration).configurationControllerGetConfiguration(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update partner logo by id
     * @param {UpdateConfigurationDto} updateConfigurationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationSignUpApi
     */
    public configurationControllerUpdateConfig(updateConfigurationDto: UpdateConfigurationDto, options?: AxiosRequestConfig) {
        return ConfigurationSignUpApiFp(this.configuration).configurationControllerUpdateConfig(updateConfigurationDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FilesApi - axios parameter creator
 * @export
 */
export const FilesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary users upload image
         * @param {File} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFileControllerRegisterCandidate: async (image: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'image' is not null or undefined
            assertParamExists('uploadFileControllerRegisterCandidate', 'image', image)
            const localVarPath = `/api/files/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FilesApi - functional programming interface
 * @export
 */
export const FilesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FilesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary users upload image
         * @param {File} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFileControllerRegisterCandidate(image: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFileControllerRegisterCandidate(image, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FilesApi - factory interface
 * @export
 */
export const FilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FilesApiFp(configuration)
    return {
        /**
         * 
         * @summary users upload image
         * @param {File} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFileControllerRegisterCandidate(image: File, options?: any): AxiosPromise<void> {
            return localVarFp.uploadFileControllerRegisterCandidate(image, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FilesApi - object-oriented interface
 * @export
 * @class FilesApi
 * @extends {BaseAPI}
 */
export class FilesApi extends BaseAPI {
    /**
     * 
     * @summary users upload image
     * @param {File} image 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public uploadFileControllerRegisterCandidate(image: File, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).uploadFileControllerRegisterCandidate(image, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PartnerLogosApi - axios parameter creator
 * @export
 */
export const PartnerLogosApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary admin create partner logo
         * @param {CreatePartnerLogoDto} createPartnerLogoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerLogoControllerCreatePartnerLogo: async (createPartnerLogoDto: CreatePartnerLogoDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPartnerLogoDto' is not null or undefined
            assertParamExists('partnerLogoControllerCreatePartnerLogo', 'createPartnerLogoDto', createPartnerLogoDto)
            const localVarPath = `/api/partner-logos/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPartnerLogoDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete partner logo by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerLogoControllerDeletePartnerLogo: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partnerLogoControllerDeletePartnerLogo', 'id', id)
            const localVarPath = `/api/partner-logos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all partner logos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerLogoControllerGetAllPartnerLogos: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/partner-logos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get partner logo by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerLogoControllerGetPartnerLogoById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partnerLogoControllerGetPartnerLogoById', 'id', id)
            const localVarPath = `/api/partner-logos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update partner logo by id
         * @param {number} id 
         * @param {UpdatePartnerLogoDto} updatePartnerLogoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerLogoControllerUpdatePartnerLogo: async (id: number, updatePartnerLogoDto: UpdatePartnerLogoDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('partnerLogoControllerUpdatePartnerLogo', 'id', id)
            // verify required parameter 'updatePartnerLogoDto' is not null or undefined
            assertParamExists('partnerLogoControllerUpdatePartnerLogo', 'updatePartnerLogoDto', updatePartnerLogoDto)
            const localVarPath = `/api/partner-logos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePartnerLogoDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerLogosApi - functional programming interface
 * @export
 */
export const PartnerLogosApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnerLogosApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary admin create partner logo
         * @param {CreatePartnerLogoDto} createPartnerLogoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerLogoControllerCreatePartnerLogo(createPartnerLogoDto: CreatePartnerLogoDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerLogoControllerCreatePartnerLogo(createPartnerLogoDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete partner logo by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerLogoControllerDeletePartnerLogo(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerLogoControllerDeletePartnerLogo(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all partner logos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerLogoControllerGetAllPartnerLogos(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerLogoControllerGetAllPartnerLogos(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get partner logo by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerLogoControllerGetPartnerLogoById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerLogoControllerGetPartnerLogoById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update partner logo by id
         * @param {number} id 
         * @param {UpdatePartnerLogoDto} updatePartnerLogoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerLogoControllerUpdatePartnerLogo(id: number, updatePartnerLogoDto: UpdatePartnerLogoDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerLogoControllerUpdatePartnerLogo(id, updatePartnerLogoDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartnerLogosApi - factory interface
 * @export
 */
export const PartnerLogosApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnerLogosApiFp(configuration)
    return {
        /**
         * 
         * @summary admin create partner logo
         * @param {CreatePartnerLogoDto} createPartnerLogoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerLogoControllerCreatePartnerLogo(createPartnerLogoDto: CreatePartnerLogoDto, options?: any): AxiosPromise<void> {
            return localVarFp.partnerLogoControllerCreatePartnerLogo(createPartnerLogoDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete partner logo by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerLogoControllerDeletePartnerLogo(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.partnerLogoControllerDeletePartnerLogo(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all partner logos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerLogoControllerGetAllPartnerLogos(options?: any): AxiosPromise<void> {
            return localVarFp.partnerLogoControllerGetAllPartnerLogos(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get partner logo by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerLogoControllerGetPartnerLogoById(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.partnerLogoControllerGetPartnerLogoById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update partner logo by id
         * @param {number} id 
         * @param {UpdatePartnerLogoDto} updatePartnerLogoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerLogoControllerUpdatePartnerLogo(id: number, updatePartnerLogoDto: UpdatePartnerLogoDto, options?: any): AxiosPromise<void> {
            return localVarFp.partnerLogoControllerUpdatePartnerLogo(id, updatePartnerLogoDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartnerLogosApi - object-oriented interface
 * @export
 * @class PartnerLogosApi
 * @extends {BaseAPI}
 */
export class PartnerLogosApi extends BaseAPI {
    /**
     * 
     * @summary admin create partner logo
     * @param {CreatePartnerLogoDto} createPartnerLogoDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerLogosApi
     */
    public partnerLogoControllerCreatePartnerLogo(createPartnerLogoDto: CreatePartnerLogoDto, options?: AxiosRequestConfig) {
        return PartnerLogosApiFp(this.configuration).partnerLogoControllerCreatePartnerLogo(createPartnerLogoDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete partner logo by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerLogosApi
     */
    public partnerLogoControllerDeletePartnerLogo(id: number, options?: AxiosRequestConfig) {
        return PartnerLogosApiFp(this.configuration).partnerLogoControllerDeletePartnerLogo(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all partner logos
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerLogosApi
     */
    public partnerLogoControllerGetAllPartnerLogos(options?: AxiosRequestConfig) {
        return PartnerLogosApiFp(this.configuration).partnerLogoControllerGetAllPartnerLogos(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get partner logo by id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerLogosApi
     */
    public partnerLogoControllerGetPartnerLogoById(id: number, options?: AxiosRequestConfig) {
        return PartnerLogosApiFp(this.configuration).partnerLogoControllerGetPartnerLogoById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update partner logo by id
     * @param {number} id 
     * @param {UpdatePartnerLogoDto} updatePartnerLogoDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerLogosApi
     */
    public partnerLogoControllerUpdatePartnerLogo(id: number, updatePartnerLogoDto: UpdatePartnerLogoDto, options?: AxiosRequestConfig) {
        return PartnerLogosApiFp(this.configuration).partnerLogoControllerUpdatePartnerLogo(id, updatePartnerLogoDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StatsApi - axios parameter creator
 * @export
 */
export const StatsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary admin get stats
         * @param {any} [page] Number of page to get and default value is 0
         * @param {any} [limit] Number of items per page and default is 0. Max is 100
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statControllerGetStats: async (page?: any, limit?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatsApi - functional programming interface
 * @export
 */
export const StatsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary admin get stats
         * @param {any} [page] Number of page to get and default value is 0
         * @param {any} [limit] Number of items per page and default is 0. Max is 100
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statControllerGetStats(page?: any, limit?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statControllerGetStats(page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StatsApi - factory interface
 * @export
 */
export const StatsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatsApiFp(configuration)
    return {
        /**
         * 
         * @summary admin get stats
         * @param {any} [page] Number of page to get and default value is 0
         * @param {any} [limit] Number of items per page and default is 0. Max is 100
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statControllerGetStats(page?: any, limit?: any, options?: any): AxiosPromise<SuccessResponseDto> {
            return localVarFp.statControllerGetStats(page, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatsApi - object-oriented interface
 * @export
 * @class StatsApi
 * @extends {BaseAPI}
 */
export class StatsApi extends BaseAPI {
    /**
     * 
     * @summary admin get stats
     * @param {any} [page] Number of page to get and default value is 0
     * @param {any} [limit] Number of items per page and default is 0. Max is 100
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsApi
     */
    public statControllerGetStats(page?: any, limit?: any, options?: AxiosRequestConfig) {
        return StatsApiFp(this.configuration).statControllerGetStats(page, limit, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StepsApi - axios parameter creator
 * @export
 */
export const StepsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary admin create step
         * @param {CreateStepDto} createStepDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerCreate: async (createStepDto: CreateStepDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createStepDto' is not null or undefined
            assertParamExists('stepControllerCreate', 'createStepDto', createStepDto)
            const localVarPath = `/api/steps/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createStepDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary admin delete a step
         * @param {number} id step ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerDeleteById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepControllerDeleteById', 'id', id)
            const localVarPath = `/api/steps/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary admin get step
         * @param {number} id Step ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerGetById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepControllerGetById', 'id', id)
            const localVarPath = `/api/steps/details/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary user get level active
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerGetLevelActive: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/steps/active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary admin get list step
         * @param {number} [page] Number of page to get and default value is 0
         * @param {number} [limit] Number of items per page and default is 0. Max is 100
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerListSteps: async (page?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/steps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary admin update step
         * @param {number} id step ID
         * @param {UpdateStepDto} updateStepDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerUpdateLevel: async (id: number, updateStepDto: UpdateStepDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stepControllerUpdateLevel', 'id', id)
            // verify required parameter 'updateStepDto' is not null or undefined
            assertParamExists('stepControllerUpdateLevel', 'updateStepDto', updateStepDto)
            const localVarPath = `/api/steps/update/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateStepDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StepsApi - functional programming interface
 * @export
 */
export const StepsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StepsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary admin create step
         * @param {CreateStepDto} createStepDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepControllerCreate(createStepDto: CreateStepDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepControllerCreate(createStepDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary admin delete a step
         * @param {number} id step ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepControllerDeleteById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepControllerDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary admin get step
         * @param {number} id Step ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepControllerGetById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepControllerGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary user get level active
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepControllerGetLevelActive(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepControllerGetLevelActive(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary admin get list step
         * @param {number} [page] Number of page to get and default value is 0
         * @param {number} [limit] Number of items per page and default is 0. Max is 100
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepControllerListSteps(page?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepControllerListSteps(page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary admin update step
         * @param {number} id step ID
         * @param {UpdateStepDto} updateStepDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stepControllerUpdateLevel(id: number, updateStepDto: UpdateStepDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stepControllerUpdateLevel(id, updateStepDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StepsApi - factory interface
 * @export
 */
export const StepsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StepsApiFp(configuration)
    return {
        /**
         * 
         * @summary admin create step
         * @param {CreateStepDto} createStepDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerCreate(createStepDto: CreateStepDto, options?: any): AxiosPromise<void> {
            return localVarFp.stepControllerCreate(createStepDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary admin delete a step
         * @param {number} id step ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerDeleteById(id: number, options?: any): AxiosPromise<SuccessResponseDto> {
            return localVarFp.stepControllerDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary admin get step
         * @param {number} id Step ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerGetById(id: number, options?: any): AxiosPromise<SuccessResponseDto> {
            return localVarFp.stepControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary user get level active
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerGetLevelActive(options?: any): AxiosPromise<SuccessResponseDto> {
            return localVarFp.stepControllerGetLevelActive(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary admin get list step
         * @param {number} [page] Number of page to get and default value is 0
         * @param {number} [limit] Number of items per page and default is 0. Max is 100
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerListSteps(page?: number, limit?: number, options?: any): AxiosPromise<SuccessResponseDto> {
            return localVarFp.stepControllerListSteps(page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary admin update step
         * @param {number} id step ID
         * @param {UpdateStepDto} updateStepDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stepControllerUpdateLevel(id: number, updateStepDto: UpdateStepDto, options?: any): AxiosPromise<void> {
            return localVarFp.stepControllerUpdateLevel(id, updateStepDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StepsApi - object-oriented interface
 * @export
 * @class StepsApi
 * @extends {BaseAPI}
 */
export class StepsApi extends BaseAPI {
    /**
     * 
     * @summary admin create step
     * @param {CreateStepDto} createStepDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StepsApi
     */
    public stepControllerCreate(createStepDto: CreateStepDto, options?: AxiosRequestConfig) {
        return StepsApiFp(this.configuration).stepControllerCreate(createStepDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary admin delete a step
     * @param {number} id step ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StepsApi
     */
    public stepControllerDeleteById(id: number, options?: AxiosRequestConfig) {
        return StepsApiFp(this.configuration).stepControllerDeleteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary admin get step
     * @param {number} id Step ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StepsApi
     */
    public stepControllerGetById(id: number, options?: AxiosRequestConfig) {
        return StepsApiFp(this.configuration).stepControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary user get level active
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StepsApi
     */
    public stepControllerGetLevelActive(options?: AxiosRequestConfig) {
        return StepsApiFp(this.configuration).stepControllerGetLevelActive(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary admin get list step
     * @param {number} [page] Number of page to get and default value is 0
     * @param {number} [limit] Number of items per page and default is 0. Max is 100
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StepsApi
     */
    public stepControllerListSteps(page?: number, limit?: number, options?: AxiosRequestConfig) {
        return StepsApiFp(this.configuration).stepControllerListSteps(page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary admin update step
     * @param {number} id step ID
     * @param {UpdateStepDto} updateStepDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StepsApi
     */
    public stepControllerUpdateLevel(id: number, updateStepDto: UpdateStepDto, options?: AxiosRequestConfig) {
        return StepsApiFp(this.configuration).stepControllerUpdateLevel(id, updateStepDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TransactionsApi - axios parameter creator
 * @export
 */
export const TransactionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary admin check payment transaction  by  id
         * @param {number} transactionId transaction ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionControllerCheckById: async (transactionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('transactionControllerCheckById', 'transactionId', transactionId)
            const localVarPath = `/api/transactions/check/{transaction_id}`
                .replace(`{${"transaction_id"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary admin get transaction  by  id
         * @param {number} id transaction ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionControllerGetById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('transactionControllerGetById', 'id', id)
            const localVarPath = `/api/transactions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get stats transactions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionControllerGetStats: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/transactions/amount/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary admin get all transaction in system
         * @param {number} [page] Number of page to get and default value is 0
         * @param {number} [limit] Number of items per page and default is 0. Max is 100
         * @param {'PENDING' | 'SUCCESS' | 'FAIL'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionControllerListCandidateProfile: async (page?: number, limit?: number, status?: 'PENDING' | 'SUCCESS' | 'FAIL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionsApi - functional programming interface
 * @export
 */
export const TransactionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransactionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary admin check payment transaction  by  id
         * @param {number} transactionId transaction ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionControllerCheckById(transactionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transactionControllerCheckById(transactionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary admin get transaction  by  id
         * @param {number} id transaction ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionControllerGetById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transactionControllerGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get stats transactions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionControllerGetStats(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transactionControllerGetStats(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary admin get all transaction in system
         * @param {number} [page] Number of page to get and default value is 0
         * @param {number} [limit] Number of items per page and default is 0. Max is 100
         * @param {'PENDING' | 'SUCCESS' | 'FAIL'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionControllerListCandidateProfile(page?: number, limit?: number, status?: 'PENDING' | 'SUCCESS' | 'FAIL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transactionControllerListCandidateProfile(page, limit, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TransactionsApi - factory interface
 * @export
 */
export const TransactionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransactionsApiFp(configuration)
    return {
        /**
         * 
         * @summary admin check payment transaction  by  id
         * @param {number} transactionId transaction ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionControllerCheckById(transactionId: number, options?: any): AxiosPromise<SuccessResponseDto> {
            return localVarFp.transactionControllerCheckById(transactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary admin get transaction  by  id
         * @param {number} id transaction ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionControllerGetById(id: number, options?: any): AxiosPromise<SuccessResponseDto> {
            return localVarFp.transactionControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get stats transactions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionControllerGetStats(options?: any): AxiosPromise<SuccessResponseDto> {
            return localVarFp.transactionControllerGetStats(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary admin get all transaction in system
         * @param {number} [page] Number of page to get and default value is 0
         * @param {number} [limit] Number of items per page and default is 0. Max is 100
         * @param {'PENDING' | 'SUCCESS' | 'FAIL'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionControllerListCandidateProfile(page?: number, limit?: number, status?: 'PENDING' | 'SUCCESS' | 'FAIL', options?: any): AxiosPromise<SuccessResponseDto> {
            return localVarFp.transactionControllerListCandidateProfile(page, limit, status, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TransactionsApi - object-oriented interface
 * @export
 * @class TransactionsApi
 * @extends {BaseAPI}
 */
export class TransactionsApi extends BaseAPI {
    /**
     * 
     * @summary admin check payment transaction  by  id
     * @param {number} transactionId transaction ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public transactionControllerCheckById(transactionId: number, options?: AxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).transactionControllerCheckById(transactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary admin get transaction  by  id
     * @param {number} id transaction ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public transactionControllerGetById(id: number, options?: AxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).transactionControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get stats transactions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public transactionControllerGetStats(options?: AxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).transactionControllerGetStats(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary admin get all transaction in system
     * @param {number} [page] Number of page to get and default value is 0
     * @param {number} [limit] Number of items per page and default is 0. Max is 100
     * @param {'PENDING' | 'SUCCESS' | 'FAIL'} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public transactionControllerListCandidateProfile(page?: number, limit?: number, status?: 'PENDING' | 'SUCCESS' | 'FAIL', options?: AxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).transactionControllerListCandidateProfile(page, limit, status, options).then((request) => request(this.axios, this.basePath));
    }
}


