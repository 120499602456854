import React from 'react';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Box, Stack } from '@mui/material';
import { ColorRing } from 'react-loader-spinner';
import {StyledGridOverlay} from '../customDataGrid/overlay';
import styles from './index.module.scss';


const LoadingSkeleton = () => {
    return (
        <>
            <Box className={styles.loading} sx={{ display : 'flex' , justifyContent : 'center' , alignItems : 'center'}}>
                <ColorRing
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                />
            </Box>
        </>
    )
};

export default function DataGridSelected(props : {
    hasSearchBar : boolean, 
    data : any, 
    columns  : any, 
    loading : boolean, 
    height : string, 
    title : string,
    onRowsSelectionHandler : any,
    dataSorting : any}) {

    const QuickSearchToolbar = () => {
        return (
            <>
                <Stack direction={'row'} spacing={3} className={styles.box_search}>
                    <h2 className={styles.title}>{props.title}</h2>
                    <GridToolbarQuickFilter
                      className={styles.search}
                      disableUnderline={true}
                      sx={{ width: '30ch', padding: '5px 0' }}
                      placeholder={''}
                      variant="outlined"
                      size="small"
                    />
                </Stack>
            </>
        );
    };
    
    return (
        <DataGrid
          sx={{ height: props.height, border: 'none' }}
          disableColumnFilter
          rowHeight={70}
          disableColumnSelector
          loading={props.loading}
          disableDensitySelector
          onRowSelectionModelChange={props.onRowsSelectionHandler}
          components={{
                Toolbar: props.hasSearchBar ? QuickSearchToolbar : null,
                LoadingOverlay: LoadingSkeleton,
                NoRowsOverlay: StyledGridOverlay,
            }}
          componentsProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
                row: {},
            }}
          autoPageSize={true}
          disableColumnMenu
          checkboxSelection
          disableRowSelectionOnClick
          rows={props.data}
          initialState={{
                sorting: {
                    sortModel: [{ field: props.dataSorting, sort: 'desc' }],
                },
            }}
          columns={props.columns}
        />
    );
}
