import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import styles from './index.module.scss';
import { useNavigate} from 'react-router-dom';
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import {useModal} from 'mui-modal-provider';
import LogOutDialog from '../../../components/forms/app-form/logout-form';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';

const IsActive = (link : string) => {
    if (location.pathname.indexOf(link) > 0) {
        return true;
    }
}

export function MainMenu () : any{
    const navigation = useNavigate();

    return (
        <React.Fragment>
            <ListItemButton
              onClick={() => {navigation('/home')}}
              className={`${ IsActive('home') ? styles.activeLink : styles.link}`}>
                <ListItemIcon>
                    <DashboardOutlinedIcon sx={{ color : IsActive('home') ? '#fff' : '#000'}} />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
            </ListItemButton>
            <ListItemButton
              onClick={() => {navigation('/steps')}}
              className={`${ IsActive('steps') ? styles.activeLink : styles.link}`}>
                <ListItemIcon>
                    <VerifiedOutlinedIcon sx={{ color : IsActive('steps') ? '#fff' : '#000'}} />
                </ListItemIcon>
                <ListItemText primary="Steps"/>
            </ListItemButton>
            <ListItemButton
              onClick={() => {navigation('/candidates')}}
              className={`${ IsActive('candidates') ? styles.activeLink : styles.link}`}>
                <ListItemIcon>
                    <FactCheckOutlinedIcon sx={{ color : IsActive('candidates') ? '#fff' : '#000'}} />
                </ListItemIcon>
                <ListItemText primary="Candidates"/>
            </ListItemButton>
            <ListItemButton
              onClick={() => {navigation('/finances')}}
              className={`${ IsActive('finances') ? styles.activeLink : styles.link}`}>
                <ListItemIcon>
                    <PriceChangeOutlinedIcon sx={{ color : IsActive('finances') ? '#fff' : '#000'}} />
                </ListItemIcon>
                <ListItemText primary="Finances"/>
            </ListItemButton>
            <ListItemButton
              onClick={() => {navigation('/partners')}}
              className={`${ IsActive('partners') ? styles.activeLink : styles.link}`}>
                <ListItemIcon>
                    <PeopleAltOutlinedIcon sx={{ color : IsActive('partners') ? '#fff' : '#000'}} />
                </ListItemIcon>
                <ListItemText primary="Partners"/>
            </ListItemButton>
            {/*<ListItemButton*/}
            {/*  onClick={() => {navigation('/users')}}*/}
            {/*  className={`${ IsActive('users') ? styles.activeLink : styles.link}`}>*/}
            {/*    <ListItemIcon>*/}
            {/*        <PeopleAltOutlinedIcon sx={{ color : IsActive('users') ? '#fff' : '#000'}} />*/}
            {/*    </ListItemIcon>*/}
            {/*    <ListItemText primary="Users"/>*/}
            {/*</ListItemButton>*/}
        </React.Fragment>
    )
}

export function SecondMenu() : any{
    const {showModal} = useModal();

    return (
        <React.Fragment>
            <ListItemButton
              onClick={() => showModal(LogOutDialog, {title: 'LOGOUT' })}
              sx={{width : '70%'}}
              className={styles.activeLogOut}>
                <ListItemIcon>
                    <LogoutIcon sx={{ color :'#fff'}} />
                </ListItemIcon>
                <ListItemText primary="LogOut"/>
            </ListItemButton>
        </React.Fragment>
    )
}