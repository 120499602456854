import {ConfirmationDialogProps} from '@/interfaces/dialog';
import React from 'react';
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Switch} from '@mui/material';
import * as yup from 'yup';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import TextField from '@mui/material/TextField';
import {LoadingButton} from '@mui/lab';
import FormControlLabel from '@mui/material/FormControlLabel';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DateTimeField} from '@mui/x-date-pickers/DateTimeField';
import dayjs from 'dayjs';
import * as serviceIPC from '../../../services/service-ipc';
import configuration from '../../../config/ipc-config';
import CustomUseMutation from '../../../hooks/customUseMutation';

const UpdateStepFormDialog: React.FC<ConfirmationDialogProps> = ({
                                                                   title,
                                                                    content,
                                                                   ...props
                                                               }) => {
    const nav = useNavigate();

    const SuccessUpdate = () => {
        toast.success('Step Updated Successfully');
        nav(0)
    }

    const schemaStep = yup.object({
        name: yup.string().required('name is required'),
        price: yup.number().required('price is required'),
        description: yup.string(),
        active: yup.boolean(),
        vote: yup.boolean(),
        end_date: yup.string().required(),
        total: yup.number(),
    }).required();

    const { control,register,getValues , formState: { errors  } } = useForm({
        resolver: yupResolver(schemaStep),
        defaultValues: {
            name: content.name,
            price: content.price,
            description : content.description,
            active : content.active,
            vote : content.vote,
            end_date : dayjs(content.end_date) as any,
            total : content?.total,
        },
    });

    const stepAPI = new serviceIPC.StepsApi(configuration);
    const { isLoading , mutate} = CustomUseMutation(
        (d : any)=>stepAPI.stepControllerUpdateLevel(content.id,d) ,
        `updatedStep-${content.id}` ,
        SuccessUpdate);
    
    const onSubmit = ()=>{
        mutate({
            ...getValues() ,
            price : parseInt(getValues('price') as any) ,
            total : parseInt(getValues('total') as any),
        } as any)
    }


    return (
        <>
            <Dialog {...props}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <Box sx={{mt: 1}}>
                        <TextField
                          margin='normal'
                          required
                          fullWidth
                          error={!!errors.name?.message}
                          {...register('name')}
                          label='Name'
                          autoFocus
                        />
                        <TextField
                          margin='normal'
                          required
                          fullWidth
                          error={!!errors.price?.message}
                          {...register('price')}
                          label='Price'
                          type={'number'}
                          autoFocus
                        />
                        <TextField
                          margin='normal'
                          fullWidth
                          error={!!errors.description?.message}
                          {...register('description')}
                          label='Description'
                          multiline
                          rows={4}
                          autoFocus
                        />
                        <Controller
                          name="end_date"
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => {
                                return (
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateTimeField
                                          fullWidth
                                          required
                                          margin='normal'
                                          label="Expire Date"
                                          format="L hh:mm a"
                                          {...field}
                                        />
                                    </LocalizationProvider>
                                )
                            }}
                        />
                        <TextField
                          margin='normal'
                          required
                          fullWidth
                          error={!!errors.total?.message}
                          {...register('total')}
                          label='Max Candidate'
                          type={'number'}
                          autoFocus
                        />
                        <FormControlLabel
                          control={
                            <Switch  {...register('active')} defaultChecked={getValues('active')}/>
                          }
                          label="Active" />
                        <FormControlLabel
                          control={
                                <Switch  {...register('vote')} defaultChecked={getValues('vote')}/>
                            }
                          label="Vote" />
                    </Box>
                </DialogContent>
                <DialogActions>
                    {/*<Button}>*/}
                    {/*    Cancel*/}
                    {/*</Button>*/}
                    <LoadingButton color="primary" variant="contained" onClick={onSubmit} loading={isLoading}>
                        Update
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default UpdateStepFormDialog;