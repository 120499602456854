import { Box, Dialog, DialogContent, DialogProps, DialogTitle } from '@mui/material';
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import * as serviceIPC from '../../../services/service-ipc';
import configuration from '../../../config/ipc-config';
import CustomUseMutation from '../../../hooks/customUseMutation';
import { MuiFileInput } from 'mui-file-input';
import { uploadFile } from '../../../util/helpers';
import CustomUseFetch from '../../../hooks/CustomUseFetch';

const CreatePartnerFormDialog: React.FC<DialogProps> = ({ title, ...props }) => {
  const {refetch} = CustomUseFetch(()=>partnerAPI.partnerLogoControllerGetAllPartnerLogos() , 'ListPartners');

  const SuccessCreate = () => {
    toast.success('Partner created Successfully');
    refetch()
    reset()
    setIsCreating(false)
  }
  const [isCreating, setIsCreating] = useState(false)
  const partnerAPI = new serviceIPC.PartnerLogosApi(configuration);
  const { isLoading, mutate } = CustomUseMutation(
    (d: any) => partnerAPI.partnerLogoControllerCreatePartnerLogo(d),
    'createPartner',
    SuccessCreate);

  const schemaLogin = yup.object({
    name: yup.string().required('name is required'),
    file: yup.mixed().required('File is required'),
    description: yup.string(),
  }).required();

  const { register, control, reset, getValues, formState: { errors } } = useForm({
    resolver: yupResolver(schemaLogin),
  });


  const onSubmit = async () => {
    setIsCreating(true)
    const partner = getValues();
    const file = await uploadFile(partner.file);
    if(file){
      mutate({
        name: partner.name,
        description: partner.description,
        url: file,
      } as any)
    }else{
      toast.error('Partner logo failed. Please try again or choose another one')
      setIsCreating(false)
    }
  }

  return (
    <>
      <Dialog {...props}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Box component='form' onSubmit={onSubmit} sx={{ mt: 1 }}>
            <TextField
              margin='normal'
              required
              fullWidth
              error={!!errors.name?.message}
              {...register('name')}
              label='Name'
              autoFocus
            />

            <Controller
              name="file"
              control={control}
              render={({ field, fieldState }: any) => (
                <MuiFileInput
                  {...field}
                  fullWidth
                  placeholder={'partner logo'}
                  label={'Partner logo'}
                  sx={{ mt: 1 }}
                  helperText={fieldState.invalid ? 'File is invalid' : ''}
                  error={fieldState.invalid}
                />
              )}
            />
            <TextField
              margin='normal'
              fullWidth
              error={!!errors.description?.message}
              {...register('description')}
              label='Description'
              multiline
              rows={4}
              autoFocus
            />
            <LoadingButton
              fullWidth
              variant='contained'
              loading={isCreating || isLoading}
              disabled={isCreating || isLoading}
              className={'app-btn'}
              onClick={onSubmit}
              sx={{
                padding: '0.9rem 0',
                marginTop: '20px',
              }}
            >
              CREATE
            </LoadingButton>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}
export default CreatePartnerFormDialog;