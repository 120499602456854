import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

export default function CustomUseMutation(callBack : any, identity : string, callbackSuccess : any) {
    return useMutation(identity, (data) =>
            callBack(data).then((res : any) => res.data?.data ?? res.data)
        , {
            onSuccess: async (data, variables, context) => {
                callbackSuccess();
            },
            onError: function (data : any) {
                toast.error(data.response.data.message ?? 'an error occurred', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
            },
        });
}