import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Grid, IconButton, LinearProgress, Stack, Typography } from '@mui/material';
import Breadcrumds from '../../components/breadcrumds';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import moment from 'moment';
import DataGridApp from '../../components/customDataGrid';
import React, { useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useModal } from 'mui-modal-provider';
import Chip from '@mui/material/Chip';
import CreateStepFormDialog from '../../components/forms/step-form/create';
import UpdateStepFormDialog from '../../components/forms/step-form/update';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import DeleteStepFormDialog from '../../components/forms/step-form/delete';
import * as serviceIPC from '../../services/service-ipc';
import configuration from '../../config/ipc-config';
import CustomUseFetch from '../../hooks/CustomUseFetch';
import EventBus from '../../util/eventBus';
import CreatePartnerFormDialog from '../../components/forms/partner-form/create';
import PartnerCard from '../../components/serviceCard/PartnerCard';

function Partners() {
    const partnerAPI = new serviceIPC.PartnerLogosApi(configuration);
    const {data, isLoading, refetch} = CustomUseFetch(()=>partnerAPI.partnerLogoControllerGetAllPartnerLogos() , 'ListPartners');
    const { showModal } = useModal();
    console.log('datata', data);
    

    useEffect(() => {
        EventBus.on('loadStep', (value : any) => {
            refetch();
        });

        return () => {
            EventBus.remove('load' , null);
        }
    }, []);

    return (
        <>
            <Stack direction='row' justifyContent={'space-between'} alignItems={'center'}>
                <Breadcrumds name={'Partners'} link={'#'} subname={'partners'} />
                <Button
                  className={'app-btn'}
                  onClick={() => showModal(CreatePartnerFormDialog, { title: 'Create partner' })}
                  startIcon={<AddIcon />}
                  sx={{
                    padding: '0.9rem 2rem',
                    marginTop: '20px',
                  }} variant="contained"
                >
                    New partner
                </Button>
            </Stack>
            <Stack sx={{
                minHeight: '60vh',
                width: '100%',
                border: '2px solid #ECEEF7',
                backgroundColor: '#FFFFFF',
                borderRadius: '6px',
                marginTop: '5%',
                padding: 5,
            }}>
                {isLoading ?
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                :
                    <Grid container spacing={4}>
                        {data?.map((partner: any) => 
                            <Grid item key={partner?.id} xs={12} md={4} lg={3}>
                                <PartnerCard partner={ partner } refetch={ refetch } />
                            </Grid>,
                        )}
                    </Grid>
                }
            </Stack>
        </>
    );
}

// Default export
export default Partners;
