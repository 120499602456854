import { Stack } from '@mui/material';
import logo from './../../assets/images/Logo-noir.png';
import styles from './index.module.scss';

const Address = () => {
  return (
      <>
          <Stack spacing={15}>
              <Stack>
                  <img src={logo} alt="logo"/>
              </Stack>
              <Stack>
                  <Stack spacing={2} direction={'row'}>
                      <h3 className={styles.title2}>Company Name :</h3>
                      <h4 className={styles.context}>Brainkode SA.</h4>
                  </Stack>
                  <Stack  spacing={2} direction={'row'}>
                      <h3 className={styles.title2}>Address :</h3>
                      <h4 className={styles.context}>Italie Bev</h4>
                  </Stack>
                  <Stack  spacing={2} direction={'row'}>
                      <h3 className={styles.title2}>Tel:</h3>
                      <h4 className={styles.context}>+236 789 879 978</h4>
                  </Stack>
              </Stack>
          </Stack>
      </>
  )
}

const Description = () => {
    return (
        <>
            <Stack spacing={5}>
                <h2 className={styles.title}>About Us :</h2>
                <Stack spacing={3}>
                    <p className={styles.context}>Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                        Asperiores error
                        itaque odit quasi tenetur. Architecto aspernatur distinctio doloremque dolorum
                        eaque illo in qui reiciendis rem repudiandae voluptate, voluptatibus? Consequuntur,
                        perspiciatis?</p>
                    <p className={styles.context}>Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                        Asperiores error
                        itaque odit quasi tenetur. Architecto aspernatur distinctio doloremque dolorum
                        eaque illo in qui reiciendis rem repudiandae voluptate, voluptatibus? Consequuntur,
                        perspiciatis?</p>
                    <p className={styles.context}>Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                        Asperiores error
                        itaque odit quasi tenetur. Architecto aspernatur distinctio doloremque dolorum
                        eaque illo in qui reiciendis rem repudiandae voluptate, voluptatibus? Consequuntur,
                        perspiciatis?</p>
                </Stack>
            </Stack>


        </>
    )
}

function AboutPage() {
    return (
        <>
            <Stack spacing={10} direction={'row'} sx={{marginTop : '10%'}}>
                <div>
                    <Address/>
                </div>
                <div>
                    <Description/>
                </div>
            </Stack>
        </>
    );
}

// Default export
export default AboutPage;
