import React, {useState} from 'react';
import {Box, Card, CardContent, Dialog, DialogContent, DialogProps, DialogTitle, Slide, Stack, Tab} from '@mui/material';
import {LoadingButton, TabContext, TabList, TabPanel} from '@mui/lab';
import {TransitionProps} from '@mui/material/transitions';
import CardMedia from '@mui/material/CardMedia';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import PersonPinTwoToneIcon from '@mui/icons-material/PersonPinTwoTone';
import Rating from '@mui/material/Rating';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ProfileFormDialog: React.FC<any> = ({title, content, ...props}) => {
    const [value, setValue] = React.useState('1');
    const [open , setOpen] = useState<boolean>(true);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <>
            <Dialog {...props}
              fullWidth
              maxWidth={'md'}
              open={open}
              TransitionComponent={Transition}
              keepMounted>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <Box>
                        <TabContext value={value}>
                            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                <TabList onChange={handleChange} aria-label="Candidature">
                                    <Tab label="Candidature" value="1"/>
                                    <Tab label="Profile" value="2"/>
                                </TabList>
                            </Box>
                            <TabPanel value="1">
                                <Stack direction={'row'} spacing={4}>
                                    <Card sx={{ width: 200 }}>
                                        <CardMedia
                                          sx={{ height: 200 }}
                                          image={content.candidature?.profile}
                                          title="Profile"
                                        />
                                        {/*<CardContent/>*/}
                                    </Card>
                                    <Card sx={{ width: 200 }}>
                                        <CardMedia
                                          sx={{ height: 200 }}
                                          image={content.candidature?.shared_profile}
                                          title="Profile2"
                                        />
                                        {/*<CardContent/>*/}
                                    </Card>
                                </Stack>
                                <Stack sx={{ mt : 2}} spacing={1}>
                                    <h3 style={{ fontSize : '1.443rem'}}>Presentation : </h3>
                                    <p style={{ maxWidth : '80%'}}>{content.candidature?.presentation}</p>
                                </Stack>
                                <Stack sx={{ mt : 2}} spacing={1} direction={'row'} justifyContent={'start'} alignItems={'end'}>
                                    <h3 style={{ fontSize : '3rem' , color : '#ab6905'}}>{content.votes} </h3>
                                    <p style={{  marginBottom : '10px'}}>vote(s)</p>
                                </Stack>
                                <Stack sx={{ mt : 2}} spacing={1} direction={'row'} justifyContent={'start'} alignItems={'end'}>
                                    <h3 style={{ fontSize : '2rem' , color : '#ce7e06'}}>{content.media} </h3>
                                    <p style={{  marginBottom : '10px'}}>Point(s) Social Media</p>
                                </Stack>
                                <Stack sx={{ mt : 2}} spacing={1} direction={'row'} justifyContent={'start'} alignItems={'end'}>
                                    <p style={{  marginBottom : '10px'}}>Notes : </p>
                                </Stack>
                                <Stack>
                                    <Rating
                                      value={Math.ceil( parseInt(content?.notes )  * 5 / 500) ?? 0}
                                      max={5}
                                      readOnly
                                      onChange={(i : any) => console.log('onChange ' + i)}
                                    />
                                </Stack>
                            </TabPanel>
                            <TabPanel value="2">
                                <Stack direction={'row'} spacing={14} justifyContent={'start'} alignItems={'center'}>
                                    <Stack spacing={5}>
                                        <Stack sx={{ mt : 2}} direction={'row'} spacing={4} justifyContent={'start'} alignItems={'center'}>
                                            <h3 style={{ fontSize : '1.243rem'}}>Phone : </h3>
                                            <p style={{ maxWidth : '80%'}}>{content.candidate?.phone}</p>
                                        </Stack>
                                        <Stack sx={{ mt : 2}} direction={'row'} spacing={4} justifyContent={'start'} alignItems={'center'}>
                                            <h3 style={{ fontSize : '1.243rem'}}>Code : </h3>
                                            <p style={{ maxWidth : '80%'}}>{content.candidate?.code}</p>
                                        </Stack>
                                        <Stack sx={{ mt : 2}} direction={'row'} spacing={4} justifyContent={'start'} alignItems={'center'}>
                                            <h3 style={{ fontSize : '1.243rem'}}>FullName : </h3>
                                            <p style={{ maxWidth : '80%'}}>{content.candidate?.profile?.full_name}</p>
                                        </Stack>
                                    </Stack>
                                    <Stack spacing={5}>
                                        <Stack sx={{ mt : 2}} direction={'row'} spacing={4} justifyContent={'start'} alignItems={'center'}>
                                            <h3 style={{ fontSize : '1.243rem'}}>City : </h3>
                                            <p style={{ maxWidth : '80%'}}>{content.candidate?.profile.ville}</p>
                                        </Stack>
                                        <Stack sx={{ mt : 2}} direction={'row'} spacing={4} justifyContent={'start'} alignItems={'center'}>
                                            <h3 style={{ fontSize : '1.243rem'}}>Sex : </h3>
                                            <p style={{ maxWidth : '80%'}}>{content.candidate?.profile?.sex}</p>
                                        </Stack>
                                        <Stack sx={{ mt : 2}} direction={'row'} spacing={4} justifyContent={'start'} alignItems={'center'}>
                                            <h3 style={{ fontSize : '1.243rem'}}>Date Creation : </h3>
                                            <p style={{ maxWidth : '80%'}}>{new Date(content.candidate?.profile.created_at).toLocaleDateString()}</p>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </TabPanel>
                        </TabContext>
                        <LoadingButton
                          fullWidth
                          variant='contained'
                          className={'app-btn'}
                          onClick={()=>setOpen(false)}
                          sx={{
                                padding: '0.9rem 0',
                                marginTop: '20px',
                            }}
                        >
                            OK
                        </LoadingButton>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ProfileFormDialog;