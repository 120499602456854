import React from 'react';
import Box from '@mui/material/Box';
import { Stack } from '@mui/material';
import styles from './index.module.scss';

export function CardFinance(
    props : {title1 : string , title2 : string , title4 : string , icon : any , link : string},
) {

    return (
        <>
                <Box className={styles.boxCard}>
                    <Stack spacing={3} direction={'column'} justifyContent={'space-around'}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Stack direction={'row'} spacing={1}>
                                <h2 className={styles.title2}>{props.title2}</h2>
                                <sub>XAF</sub>
                                <h1 className={styles.title}>{props.title1}</h1>
                            </Stack>
                            {props.icon}
                        </Stack>
                    </Stack>
                </Box>
        </>
    );
}

export default CardFinance;