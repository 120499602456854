import React, {useState} from 'react';
import {TransitionProps} from '@mui/material/transitions';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    Stack,
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import * as serviceIPC from '../../../services/service-ipc';
import configuration from '../../../config/ipc-config';
import CustomUseMutation from '../../../hooks/customUseMutation';
import {toast} from 'react-toastify';
import EventBus from '../../../util/eventBus';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const DeleteCandidateFormDialog: React.FC<any> = ({title, content, ...props}) => {
    const [open , setOpen] = useState<boolean>(true);
    const candidatureAPi = new serviceIPC.CandidaturesApi(configuration);

    const SuccessDelete = () => {
        toast.success('Candidature Deleted Successfully');
        EventBus.dispatch('loadCandidate' , null);
        setOpen(false);
    }
    const { isLoading , mutate} = CustomUseMutation(
        ()=> candidatureAPi.candidatureControllerDeleteCandidature(content.candidate.id , content.level) ,
        `deleteCandidate-${content.id}` ,
        SuccessDelete);



    return (
        <>
            <Dialog {...props}
              fullWidth
              open={open}
              TransitionComponent={Transition}
              keepMounted>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <Box sx={{mt: 1}}>
                        <DialogContentText>
                            Ldolor sit amet, consectetur adipisicing elit.
                            Dicta distinctio dolorem hic, ipsa magni maxime necessitatibus
                            nulla quas repellat voluptas?
                        </DialogContentText>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Stack direction={'row'} spacing={3}>
                        <Button
                          fullWidth
                          sx={{
                              padding: '0.9rem 3rem',
                          }}
                          onClick={()=>setOpen(false)}
                          variant="outlined"
                        >
                            CANCEL
                        </Button>
                        <LoadingButton
                          color="error"
                          sx={{
                              padding: '0.9rem 3rem',
                          }}
                          variant="contained"
                          fullWidth
                          onClick={()=>mutate()}
                          loading={isLoading}>
                            DELETE
                        </LoadingButton>
                    </Stack>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DeleteCandidateFormDialog;