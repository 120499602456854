import { Stack } from '@mui/material';
import Card from '../../components/home/card';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import * as serviceIPC from './../../services/service-ipc';
import configuration from '../../config/ipc-config';
import CustomUseFetch from '../../hooks/CustomUseFetch';

function HomePage() {
  const stepAPI = new serviceIPC.StatsApi(configuration);
  const transactionAPI = new serviceIPC.TransactionsApi(configuration);
  const { data } = CustomUseFetch(() => stepAPI.statControllerGetStats(), 'Stats');
  const { data: dataT } = CustomUseFetch(() => transactionAPI.transactionControllerGetStats(), 'StatsTransactions');

  return (
    <>
      <Stack spacing={3} justifyContent={'space-around'} direction={'row'}>
        <Card title1={'Steps'} title2={data?.steps ?? 0} title4={''} icon={<VerifiedOutlinedIcon sx={{ fontSize: '50px', color: 'rgba(13,34,66,0.75)' }} />} link={'#'} />
        <Card title1={'Candidates'} title2={data?.candidates ?? 0} title4={''} icon={<PeopleAltOutlinedIcon sx={{ fontSize: '50px', color: 'rgba(13,34,66,0.75)' }} />} link={'#'} />
        <Card title1={'Transactions '} title2={dataT?.success ?? 0} title4={'XAF'} icon={<PriceChangeOutlinedIcon sx={{ fontSize: '50px', color: 'rgba(13,34,66,0.75)' }} />} link={'#'} />
      </Stack>
    </>
  );
}

// Default export
export default HomePage;
