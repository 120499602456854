import { Box, Dialog, DialogContent, DialogProps, DialogTitle, FormControlLabel, FormGroup, LinearProgress, Switch } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import * as serviceIPC from '../../../services/service-ipc';
import configuration from '../../../config/ipc-config';
import CustomUseMutation from '../../../hooks/customUseMutation';
import CustomUseFetch from '../../../hooks/CustomUseFetch';

const SetConfigFormDialog: React.FC<DialogProps> = ({ title, ...props }) => {
  const SuccessCreate = () => {
    toast.success('Settings updated Successfully');
    refetch()
  }
  const [online, setOnline] = useState(false)
  const [free, setFree] = useState(false)
  const [sms, setSms] = useState(false)
  const configAPI = new serviceIPC.ConfigurationSignUpApi(configuration);
  const {data, isLoading: isLoadingConfig, refetch} = CustomUseFetch(()=>configAPI.configurationControllerGetConfiguration(), 'getConfigs');
  const { isLoading, mutate } = CustomUseMutation(
    (d: any) => configAPI.configurationControllerUpdateConfig(d),
    'setConfig',
    SuccessCreate);

    useEffect(() => {
        setOnline(data?.online)
        setFree(data?.free)
        setSms(data?.sms)
    },[data])

  const onSubmit = async () => {
    mutate({online, free, sms} as any)
  }

  return (
    <>
      <Dialog {...props}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Box component='form' onSubmit={onSubmit} sx={{ mt: 1 }}>
                {isLoadingConfig ?
                    <Box sx={{ width: '100%', textAlign: 'center' }}>
                        <Box>Loading...</Box>
                        <LinearProgress />
                    </Box>
                :
                    <FormGroup>
                        <FormControlLabel checked={online} onClick={() => setOnline(!online)} control={<Switch name='Online' />} label="Online" />
                        <FormControlLabel checked={free} onClick={() => setFree(!free)} control={<Switch name='Free' />} label="Free" />
                        <FormControlLabel checked={sms} onClick={() => setSms(!sms)} control={<Switch name='Sms' />} label="Sms" />
                    </FormGroup>
                }
            <LoadingButton
              fullWidth
              variant='contained'
              loading={isLoading}
              disabled={isLoading}
              className={'app-btn'}
              onClick={onSubmit}
              sx={{
                padding: '0.9rem 0',
                marginTop: '20px',
              }}
            >
              UPDATE
            </LoadingButton>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}
export default SetConfigFormDialog;