import React, {useState} from 'react';
import {TransitionProps} from '@mui/material/transitions';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    Stack,
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useNavigate} from 'react-router-dom';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const LogOutDialog: React.FC<any> = ({title, ...props}) => {
    const [open , setOpen] = useState<boolean>(true);
    const nav = useNavigate();
    const logOut = () => {
      localStorage.clear();
      nav(0);
    }
    return (
        <>
            <Dialog {...props}
              fullWidth
              open={open}
              TransitionComponent={Transition}
              keepMounted>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <Box sx={{mt: 1}}>
                        <DialogContentText>
                            Are you sure you want to logout ?
                        </DialogContentText>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Stack direction={'row'} spacing={3}>
                        <Button
                          fullWidth
                          sx={{
                                padding: '0.9rem 3rem',
                            }}
                          onClick={()=>setOpen(false)}
                          variant="outlined"
                        >
                            CANCEL
                        </Button>
                        <LoadingButton
                          color="error"
                          sx={{ padding: '0.9rem 3rem' }}
                          variant="contained"
                          fullWidth
                          onClick={logOut}
                          >
                            LOGOUT
                        </LoadingButton>
                    </Stack>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default LogOutDialog;