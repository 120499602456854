// External imports
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import React, {StrictMode} from 'react';

// Local imports
import App from './app';
import initI18n from './lang';
import reportWebVitals from './util/web-vitals';
import './styles/main.scss';
import CssBaseline from '@mui/material/CssBaseline';
import {ErrorBoundary} from 'react-error-boundary';
import {ThemeProvider} from '@mui/material/styles';
import {QueryClient, QueryClientProvider} from 'react-query';
import ModalProvider from 'mui-modal-provider';
import 'react-toastify/dist/ReactToastify.css';



import theme from './styles/theme';
import {ReactQueryDevtools} from 'react-query/devtools';
import {ToastContainer} from 'react-toastify';

// Global initialization
initI18n();

const htmlRoot = document.getElementById('root') as HTMLElement;
const reactRoot = ReactDOM.createRoot(htmlRoot);


// Create a client
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            staleTime: 0,
        },
    },
})

function ErrorFallback(props: { error: any, resetErrorBoundary: any }) {
    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre>{props.error.message}</pre>
            <button onClick={props.resetErrorBoundary}>Try again</button>
        </div>
    )
}

reactRoot.render(
    process.env.REACT_APP_SCRICT_MODE === 'true' ? (
        <StrictMode>
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onReset={() => {}}>
                <BrowserRouter>
                    <ThemeProvider theme={theme}>
                        <CssBaseline/>
                        <QueryClientProvider client={queryClient}>
                            <ModalProvider>
                                <App />
                            </ModalProvider>
                            <ToastContainer />
                            <ReactQueryDevtools initialIsOpen={false} />
                        </QueryClientProvider>
                    </ThemeProvider>
                </BrowserRouter>
            </ErrorBoundary>
        </StrictMode>
    ) : (
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {}}>
            <BrowserRouter>
                <ThemeProvider theme={theme}>
                    <CssBaseline/>
                    <QueryClientProvider client={queryClient}>
                        <ModalProvider>
                            <App />
                        </ModalProvider>
                        <ToastContainer />
                        <ReactQueryDevtools initialIsOpen={false} />
                    </QueryClientProvider>
                </ThemeProvider>
            </BrowserRouter>
        </ErrorBoundary>
    ),
);

if (process.env.REACT_APP_REPORT_WEB_VITALS === 'true') {
    // If you want to start measuring performance in your app-form, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    // eslint-disable-next-line no-console
    reportWebVitals(console.log);
}
