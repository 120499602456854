import React, {useState} from 'react';
import {TransitionProps} from '@mui/material/transitions';
import {Avatar, Button, Dialog, DialogContent, DialogTitle, Slide, Stack} from '@mui/material';
import * as yup from 'yup';
import {MuiFileInput} from 'mui-file-input';
import TextField from '@mui/material/TextField';
import {LoadingButton} from '@mui/lab';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {getUrl, uploadFile} from '../../../util/helpers';
import * as serviceIPC from '../../../services/service-ipc';
import configuration from '../../../config/ipc-config';
import CustomUseMutation from '../../../hooks/customUseMutation';
import {toast} from 'react-toastify';
import EventBus from '../../../util/eventBus';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});



const EditCandidateFormDialog: React.FC<any> = ({title, content, ...props}) => {

    const [open , setOpen] = useState<boolean>(true);
    const SuccessUpdate = () => {
        toast.success('Candidature Updated Successfully');
        EventBus.dispatch('loadCandidate' , null);
        setOpen(false);
    }

    const candidatureAPI = new serviceIPC.CandidaturesApi(configuration);
    const { isLoading , mutate} = CustomUseMutation(
        (d : any)=>{
            const n = d.notes;
            const m = d.media;
            delete d.notes;
            delete d.media;
            return Promise.all([
                candidatureAPI.candidatureControllerAdminUpdateCandidature(content.candidate.id,content.level,d),
                candidatureAPI.candidatureControllerNoteCandidate(content.candidate.id,n,m,content.level),
            ])
        } ,
        `updatedCandidature-${content.id}` ,
        SuccessUpdate);

    const schemaCandidate = yup.object({
        profile: yup.string().required('profile is required'),
        shared_profile: yup.string().required('shared profile is required'),
        presentation: yup.string(),
        notes: yup.number().max(500),
        media: yup.number().max(1000),
    }).required();

    const { register , control,getValues ,  watch , formState: { errors   } } = useForm({
        resolver: yupResolver(schemaCandidate),
        defaultValues: {
            profile: undefined,
            shared_profile: undefined,
            presentation : content.candidature.presentation,
            notes : content.notes,
            media : content.media,
        },
    });

    const onSubmit = async (d: any) => {
        let candidature : any = getValues();
        const profile =  candidature?.profile ? await uploadFile(candidature.profile) : null;
        const shared_profile = candidature?.shared_profile ? await uploadFile(candidature.shared_profile) : null;
        profile ? candidature = {...candidature , profile} : delete candidature?.profile;
        shared_profile ? candidature = {...candidature , shared_profile} : delete candidature?.shared_profile;
        mutate({
            ...candidature ,
            notes : parseInt(candidature.notes) <= 500 && parseInt(candidature.notes) >= 0 ?
                parseInt(candidature.notes) :
                parseInt(candidature.notes) < 0 ? 0 : 500,
            media : parseInt(candidature.media) <= 1000 && parseInt(candidature.media) >= 0 ?
                parseInt(candidature.media) :
                parseInt(candidature.media) < 0 ? 0 : 1000,
        });
    }


    return (
        <>
            <Dialog {...props}
              fullWidth
              maxWidth={'md'}
              open={open}
              TransitionComponent={Transition}
              keepMounted>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <Stack spacing={3} sx={{ marginTop : '10px'}}>
                        <Stack direction={'row'} spacing={3}>
                            <Stack spacing={3}>
                                <Controller
                                  name="profile"
                                  control={control}
                                  render={({ field, fieldState }: any) => (
                                        <MuiFileInput
                                          {...field}
                                          label={'profile'}
                                          helperText={fieldState.invalid ? 'File is invalid' : ''}
                                          error={fieldState.invalid}
                                        />
                                    )}
                                />
                                <Avatar
                                  alt="Remy Sharp"
                                  variant={'rounded'}
                                  src={getUrl(watch('profile')) ?? content.candidature.profile}
                                  sx={{width: '100%', height: 200}}
                                />
                            </Stack>
                            <Stack spacing={3}>
                                <Controller
                                  name="shared_profile"
                                  control={control}
                                  render={({ field, fieldState }: any) => (
                                        <MuiFileInput
                                          {...field}
                                          label={'shared'}
                                          helperText={fieldState.invalid ? 'File is invalid' : ''}
                                          error={fieldState.invalid}
                                        />
                                    )}
                                />
                                <Avatar
                                  alt="Remy Sharp"
                                  variant={'rounded'}
                                  src={getUrl(watch('shared_profile')) ?? content.candidature.shared_profile}
                                  sx={{width: '100%', height: 200}}
                                />
                            </Stack>
                        </Stack>
                        <Stack spacing={3}>
                            <TextField
                              id="presentation"
                              label="presentation"
                              error={!!errors.presentation?.message}
                              {...register('presentation')}
                              multiline
                              rows={4}
                            />
                            <TextField
                              type={'number'}
                              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*'}}
                              error={!!errors.notes?.message}
                              {...register('notes' , {max : 500 , min : 0})}
                              id="note"
                              InputLabelProps={{
                                  shrink: true,
                              }}
                              label="Note"
                            />
                            <TextField
                              type={'number'}
                              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*'}}
                              error={!!errors.media?.message}
                              {...register('media' , {max : 1000 , min : 0})}
                              id="media"
                              InputLabelProps={{
                                    shrink: true,
                                }}
                              label="Note Media"
                            />
                        </Stack>
                        <Stack direction={'row'} spacing={2}>
                            <Button
                              fullWidth
                              color={'error'}
                              onClick={()=>setOpen(false)}
                              variant="outlined"
                            >
                                CANCEL
                            </Button>
                            <LoadingButton
                              fullWidth
                              variant='contained'
                              loading={isLoading}
                              className={'app-btn'}
                              onClick={onSubmit}
                              sx={{
                                    padding: '0.9rem 0',
                                    marginTop: '20px',
                                }}
                            >
                                UPDATE
                            </LoadingButton>
                        </Stack>
                    </Stack>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default EditCandidateFormDialog;