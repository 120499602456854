import {ConfirmationDialogProps} from '@/interfaces/dialog';
import React from 'react';
import {Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import * as serviceIPC from '../../../services/service-ipc';
import configuration from '../../../config/ipc-config';
import CustomUseMutation from '../../../hooks/customUseMutation';

const DeleteStepFormDialog: React.FC<ConfirmationDialogProps> = ({
                                                                     title,
                                                                     description,
                                                                     content,
                                                                     ...props
                                                                 }) => {

    const nav = useNavigate();

    const SuccessDelete = () => {
        toast.success('Step Deleted Successfully');
        nav(0)
    }

    const onSubmit = (d : any)=>{
        mutate()
    }

    const stepAPI = new serviceIPC.StepsApi(configuration);
    const {data  , isLoading , mutate} = CustomUseMutation(
        (d : any)=>stepAPI.stepControllerDeleteById(content.id) ,
        `deleteStep-${content.id}` ,
        SuccessDelete);


    return (
        <>
            <Dialog {...props}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <Box sx={{mt: 1}}>
                        <DialogContentText>{description}</DialogContentText>
                    </Box>
                </DialogContent>
                <DialogActions>
                    {/*<Button>*/}
                    {/*    Cancel*/}
                    {/*</Button>*/}
                    <LoadingButton color="error" variant="contained" onClick={onSubmit} loading={isLoading}>
                        Delete
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DeleteStepFormDialog;