import React, {useEffect, useState} from 'react';
import DataGridSelected from '../../components/DataGridSelected';
import styles from './index.module.scss';
import {Avatar, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import UseListFetch from '../../hooks/useListFetch';
import {UseCrudRequest} from '../../hooks/useCrudRequest';
import {AvatarGroup} from '@mui/lab';
import Rating from '@mui/material/Rating';
import {useModal} from 'mui-modal-provider';
import ProfileFormDialog from '../../components/forms/candidature-form/profile-form';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import EditCandidateFormDialog from './../../components/forms/candidature-form/edit-candidate';
import EventBus from '../../util/eventBus';
import * as serviceIPC from '../../services/service-ipc';
import configuration from '../../config/ipc-config';
import CustomUseFetch from '../../hooks/CustomUseFetch';
import CustomUseMutation from '../../hooks/customUseMutation';
import {toast} from 'react-toastify';
import DeleteCandidateFormDialog from '../../components/forms/candidature-form/delete-candidate';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const SuccessUpdate = () => {
    toast.success('Candidature Updated Successfully');
    EventBus.dispatch('loadCandidate' , null);
}

const ListCandidature = (props : {id : string}) => {

    const {showModal} = useModal();
    const  [rowsSelected , setRowSelected] = useState<Array<number>>([]);
    const  [stepSelected , setStepSelected] = useState();
    const stepAPI = new serviceIPC.StepsApi(configuration);
    const candidatureAPI = new serviceIPC.CandidaturesApi(configuration);
    const {data : dataStep } = CustomUseFetch(()=>stepAPI.stepControllerListSteps() , 'ListSteps');

    const columns = [
        {
            field: 'id',
            headerName: 'Profile',
            flex: 1,
            minWidth: 250,
            renderCell: (d: any) => {
                return (
                    <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={3}>
                        <AvatarGroup max={4}>
                            <Avatar alt="C" src={d.row.candidature?.profile} />
                            <Avatar alt="A" src={d.row.candidature?.shared_profile} />
                        </AvatarGroup>
                        <h4 className={styles.text}>{d.row.candidate?.profile.full_name}</h4>
                    </Stack>)
            },
        },
        {
            field: 'code',
            headerName: 'Code',
            flex: 1,
            minWidth: 60,
            renderCell: (d: any) => {
                return (<h3 className={styles.text}>{d.row?.candidate?.code}</h3>)
            },
        },
        // {
        //     field: 'phone',
        //     headerName: 'Phone',
        //     flex: 1,
        //     minWidth: 150,
        //     renderCell: (d: any) => {
        //         return (<h3 className={styles.text}>{d.row?.candidate.phone}</h3>)
        //     },
        // },
        {
            field: 'votes',
            headerName: 'Votes',
            flex: 1,
            minWidth: 50,
            renderCell: (d: any) => {
                return (<h3 className={styles.text}>{d.row?.votes}</h3>)
            },
        },
        {
            field: 'media',
            headerName: 'Media',
            flex: 1,
            minWidth: 50,
            renderCell: (d: any) => {
                return (<h3 className={styles.text}>{d.row?.media}</h3>)
            },
        },
        {
            field: 'notes',
            headerName: 'Notes',
            flex: 1,
            minWidth: 150,
            renderCell: (d: any) => {
                return ( <Rating
                  value={Math.ceil( parseInt(d.row.notes)  * 5 / 500) ?? 0}
                  max={5}
                  readOnly
                  onChange={(i : any) => console.log('onChange ' + i)}
                />)
            },
        },
        {
            field: 'total',
            headerName: 'Total',
            flex: 1,
            minWidth: 50,
            renderCell: (d: any) => {
                return ( <h3 className={styles.text}>{
                    parseInt(d.row?.votes ?? 0) + (parseInt(d.row.notes ?? 0) ) + (parseInt(d.row.media ?? 0))
                } Pt</h3>)
            },
        },
        {
            field: 'action',
            headerName: '',
            flex: 1,
            minWidth: 150,
            renderCell: (d: any) => {
                return (
                    <Stack direction={'row'} spacing={1}>
                        <IconButton
                          onClick={() => showModal(ProfileFormDialog, {title: `Candidate : ${d.row.candidate.code}` , content : d.row})}
                        >
                            <RemoveRedEyeOutlinedIcon/>
                        </IconButton>
                        <IconButton
                          onClick={() => showModal(EditCandidateFormDialog, {title: `Update Candidature  : ${d.row.candidate.code}` , content : {...d.row , level : props.id}})}
                        >
                            <ManageAccountsOutlinedIcon/>
                        </IconButton>
                        <IconButton
                          onClick={() => showModal(DeleteCandidateFormDialog, {title: `Delete Candidate : ${d.row.candidate.code}` , content : {...d.row , level : props.id}})}
                        >
                            <DeleteOutlineOutlinedIcon color={'error'}/>
                        </IconButton>
                    </Stack>
                )

            },
        },
    ];

    const onRowsSelectionHandler = (ids : any) => {
        setRowSelected(ids)
    };
    const handleChange = (event: SelectChangeEvent) => {
        setStepSelected(event.target.value as any);
    };

    const {list} = UseCrudRequest('candidatures/level');
    const {isLoading , data , refetch } = UseListFetch(()=> list(props.id), `listCandidates-${props.id}`);

    useEffect(() => {
        EventBus.on('loadCandidate', (value : any) => {
            refetch();
        });

        return () => {
            EventBus.remove('load' , null);
        }
    }, []);

    useEffect(()=>{
        dataStep?.length ? setStepSelected(dataStep[0].id) : null;
    },[dataStep]);

    const profileAdd = ()=>{
        const p = [];
        for (const row of rowsSelected) {
            const d = data?.findIndex((d : any)=> d.id === row);
            const s = data[d];
            p.push(
                candidatureAPI.candidatureControllerAdminAddCandidature(s.candidate.id , stepSelected as any ,{
                    profile: s.candidature?.profile,
                    shared_profile: s.candidature?.shared_profile,
                    presentation: s.candidature?.presentation,
                } as any))
        }
        return Promise.all(p);
    }

    const { isLoading : loadingCandidature , mutate} = CustomUseMutation(
        ()=> profileAdd() ,
        'addCandidature' ,
        SuccessUpdate);

    return (
        <>
            <Stack height={'58vh'}>
                { rowsSelected.length > 0  ? 
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} className={styles.bgSelected}>
                        <span> <b>{rowsSelected.length} </b>Candidature(s) Selected</span>
                        <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={2}>
                            <span>Add to Step : </span>
                            <FormControl>
                                <InputLabel sx={{color : '#0D2242BF'}} id="step">Step</InputLabel>
                                <Select
                                  sx={{color : '#0D2242BF' , minWidth : '50px' , textTransform : 'uppercase'}}
                                  labelId="step"
                                  id="step-select"
                                  onChange={handleChange}
                                  value={stepSelected}
                                  label="Step"
                                >
                                    {dataStep?.map((d : any)=>{
                                        return <MenuItem  sx={{ textTransform : 'uppercase'}} value={d.id} key={d.id}>{d.name}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                            <Button variant="contained" onClick={()=>mutate()}>OK</Button>
                        </Stack>
                    </Stack> : 
                        <></>
                    }
                <DataGridSelected
                  title={''}
                  height={'58vh'}
                  data={data ?? []}
                  dataSorting={[]}
                  columns={columns}
                  onRowsSelectionHandler={onRowsSelectionHandler}
                  hasSearchBar={false}
                  loading={isLoading}
                />
            </Stack>
        </>
    );
};

export default ListCandidature;