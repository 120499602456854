// External imports
import {Routes, Route, Navigate} from 'react-router-dom';

// Local imports
import HomePage from '../pages/Home';
import {MainMenu, SecondMenu} from '../components/menus/main';
import Dashboard from '../layouts/dashboard';
import Steps from '../pages/steps';
import Candidates from '../pages/candidats';
import AboutPage from '../pages/about';
import Finance from '../pages/finance';
import {AccountMenu} from '../components/menus/account';
import React from 'react';
import LoginPage from '../pages/login';
import {VerifyAuth} from '../util/verifyAuth';
import Partners from '../pages/partners';



// Component definition
function App() {
    const isLoggedIn = VerifyAuth();

    return (
    <Routes>
        <Route path='/' element={
            isLoggedIn ?
                <Dashboard mainMenu={<MainMenu/>}
                  secondMenu={<SecondMenu/>} 
                  accountMenu={<AccountMenu/>}
                /> :
                <Navigate to={'/login'} replace/>
        }>
         <Route path="home" index={true} element={<HomePage />} />
         <Route path={'/'}  element={ <Navigate to="home" /> }/>
         <Route path="steps" element={<Steps />} />
         <Route path="partners" element={<Partners />} />
         <Route path="candidates" element={<Candidates />} />
         <Route path="about" element={<AboutPage />} />
         <Route path="finances" element={<Finance />} />
        </Route>
        <Route path='/login' element={isLoggedIn ? <Navigate to={'/home'} replace /> : <LoginPage />} />
    </Routes>
  );
}

// Default export
export default App;
