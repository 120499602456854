import axiosConfig from '../config/axios-config';
import authHeader from '../util/autHeader';

export function UseCrudRequest (path : string , subPathCreate  = ''  , subPathUpdate = '' , subPathDelete = '') {
    async function list(params='') {
        return axiosConfig.get(`/${path}/${params}`, {
            headers: authHeader(),
        });
    }

    function getById(id : string) {
        return axiosConfig.get(`/${path}/${id}/`, {
            headers: authHeader(),
        });
    }

    function updateById(id : string ,data : object) {
        return axiosConfig.put(`/${path}/${subPathUpdate}${id}/`, data ,{
            headers: authHeader(),
        });
    }

    function deleteById(id : string) {
        return axiosConfig.delete(`/${path}/${subPathDelete}${id}/`, {
            headers: authHeader(),
        });
    }

    function create(data : string){
        return axiosConfig.post(`/${path}/${subPathCreate}`,  data ,{
            headers: authHeader(),
        });
    }

    return {list , getById , updateById , create , deleteById};

}