import React, {useEffect, useState} from 'react';
import {TransitionProps} from '@mui/material/transitions';
import {
    Avatar,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Slide,
    Stack,
} from '@mui/material';
import * as yup from 'yup';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {getUrl, uploadFile} from '../../../util/helpers';
import {MuiFileInput} from 'mui-file-input';
import TextField from '@mui/material/TextField';
import {LoadingButton} from '@mui/lab';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import EventBus from '../../../util/eventBus';
import * as serviceIPC from '../../../services/service-ipc';
import configuration from '../../../config/ipc-config';
import CustomUseMutation from '../../../hooks/customUseMutation';
import {toast} from 'react-toastify';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const InscriptionDialog: React.FC<any> = ({title, content, ...props}) => {

    const stepAPI = new serviceIPC.CandidatesApi(configuration);
    const [open , setOpen] = useState<boolean>(true);
    const { isLoading , mutate} = CustomUseMutation(
        (d : any)=>stepAPI.candidateControllerCreateCandidate(d) ,
        'createCandidate' , ()=>{
            toast.success('Candidate Created Successfully');
            EventBus.dispatch('loadCandidate' , null);
            setOpen(false);
        });

    const schemaCandidate = yup.object({
        profile: yup.string().required('profile is required'),
        shared_profile: yup.string().required('shared profile is required'),
        presentation: yup.string(),
        phone: yup.string(),
        full_name: yup.string(),
        ville: yup.string(),
        sex: yup.string(),
    }).required();

    const { register , control,getValues ,  watch , formState: { errors   } } = useForm({
        resolver: yupResolver(schemaCandidate),
        defaultValues: {
            profile: undefined,
            shared_profile: undefined,
            presentation : '',
            sex : '',
            ville : '',
            full_name : '',
            phone : '',
        },
    });


    const onSubmit = async () => {
        const candidature = getValues();
        const profile =  await uploadFile(candidature.profile) ?? candidature.profile;
        const shared_profile =  await uploadFile(candidature.shared_profile) ?? candidature.shared_profile;
        mutate({
            phone: candidature.phone,
            profile: {
                full_name: candidature.full_name,
                sex: candidature.sex,
                ville: candidature.ville,
            },
            candidature: {
                profile: profile,
                shared_profile: shared_profile,
                presentation: candidature.presentation,
            },
        } as any)
    }

    return(
        <>
            <Dialog {...props}
              fullWidth
              maxWidth={'md'}
              open={open}
              TransitionComponent={Transition}
              keepMounted>
                <DialogTitle><b>{title}</b></DialogTitle>
                <DialogContent>
                    <Stack spacing={3}>
                        <Stack direction={'row'} spacing={3}>
                            <FormControl fullWidth>
                                <TextField
                                  id="phone"
                                  label="Phone"
                                  error={!!errors.phone?.message}
                                  {...register('phone')}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <TextField
                                  id="full_name"
                                  label="Full Name"
                                  error={!!errors.full_name?.message}
                                  {...register('full_name')}
                                />
                            </FormControl>
                        </Stack>
                        <Stack direction={'row'} spacing={3}>
                            <FormControl fullWidth>
                                <TextField
                                  id="ville"
                                  label="City"
                                  error={!!errors.ville?.message}
                                  {...register('ville')}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel id="sex">Sex</InputLabel>
                                <Select
                                  labelId="sex"
                                  id="level-select"
                                  label="Sex"
                                  error={!!errors.sex?.message}
                                  {...register('sex')}
                                >
                                    <MenuItem value={'MALE'}>HOMME</MenuItem>
                                    <MenuItem value={'FEMALE'}>FEMME</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                    </Stack>
                    <Divider sx={{ margin : '30px'}}>
                        <Chip label="CANDIDATURE" color="primary" />
                    </Divider>
                    <Stack spacing={3}>
                        <Stack direction={'row'} spacing={3}>
                            <Stack spacing={3}>
                                <Controller
                                  name="profile"
                                  control={control}
                                  render={({ field, fieldState }: any) => (
                                        <MuiFileInput
                                          {...field}
                                          placeholder={'Public Profile'}
                                          label={'Profile'}
                                          helperText={fieldState.invalid ? 'File is invalid' : ''}
                                          error={fieldState.invalid}
                                        />
                                    )}
                                />
                                <Avatar
                                  alt="Remy Sharp"
                                  variant={'rounded'}
                                  src={getUrl(watch('profile')) ?? undefined}
                                  sx={{width: '100%', height: 200}}
                                />
                            </Stack>
                            <Stack spacing={3}>
                                <Controller
                                  name="shared_profile"
                                  control={control}
                                  render={({ field, fieldState }: any) => (
                                        <MuiFileInput
                                          {...field}
                                          placeholder={'Shared Profile'}
                                          label={'Shared'}
                                          helperText={fieldState.invalid ? 'File is invalid' : ''}
                                          error={fieldState.invalid}
                                        />
                                    )}
                                />
                                <Avatar
                                  alt="Remy Sharp"
                                  variant={'rounded'}
                                  src={getUrl(watch('shared_profile')) ?? undefined}
                                  sx={{width: '100%', height: 200}}
                                />
                            </Stack>
                        </Stack>

                        <Stack spacing={3}>
                            <TextField
                              id="presentation"
                              label="presentation"
                              error={!!errors.presentation?.message}
                              {...register('presentation')}
                              multiline
                              rows={4}
                            />
                        </Stack>
                        <Stack direction={'row'} spacing={4}>
                            <Button
                              fullWidth
                              color={'error'}
                              onClick={()=>setOpen(false)}
                              variant="outlined"
                            >
                                CANCEL
                            </Button>
                            <LoadingButton
                              fullWidth
                              variant='contained'
                              loading={isLoading}
                              onClick={onSubmit}
                              sx={{
                                    padding: '0.9rem 0',
                                }}
                            >
                                CREATE
                            </LoadingButton>
                        </Stack>
                    </Stack>
                </DialogContent>
            </Dialog>
        </>
    )
}
export default InscriptionDialog;