import {Button, IconButton, Stack, Typography} from '@mui/material';
import Breadcrumds from '../../components/breadcrumds';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import moment from 'moment';
import DataGridApp from '../../components/customDataGrid';
import React, {useEffect} from 'react';
import AddIcon from '@mui/icons-material/Add';
import {useModal} from 'mui-modal-provider';
import Chip from '@mui/material/Chip';
import CreateStepFormDialog from '../../components/forms/step-form/create';
import UpdateStepFormDialog from '../../components/forms/step-form/update';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import DeleteStepFormDialog from '../../components/forms/step-form/delete';
import * as serviceIPC from '../../services/service-ipc';
import configuration from '../../config/ipc-config';
import CustomUseFetch from '../../hooks/CustomUseFetch';
import EventBus from '../../util/eventBus';

function Steps() {
    const stepAPI = new serviceIPC.StepsApi(configuration);
    const {data, isLoading , refetch} = CustomUseFetch(()=>stepAPI.stepControllerListSteps() , 'ListSteps');
    const {showModal} = useModal();

    useEffect(() => {
        EventBus.on('loadStep', (value : any) => {
            refetch();
        });

        return () => {
            EventBus.remove('load' , null);
        }
    }, []);

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            renderCell: (d: any) => {
                return (
                    <>
                        <Stack paddingLeft={'15px'}>
                            <Typography variant={'body2'} color='primary' fontWeight='bold'>
                                {d.row.id}
                            </Typography>
                        </Stack>
                    </>
                )
            },
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            minWidth: 180,
            renderCell: (d: any) => {
                return (
                    <Typography variant={'body2'} color='primary' fontWeight='bold'>
                        {d.row.name}
                    </Typography>
                )
            },
        },
        {
            field: 'price',
            headerName: 'Price',
            flex: 1,
            minWidth: 150,
            renderCell: (d: any) => {
                return (
                    <Typography variant={'body2'} fontWeight='bold'>
                        {d.row.price} XAF
                    </Typography>
                )
            },
        },
        {
            field: 'total',
            headerName: 'Total',
            flex: 1,
            minWidth: 80,
            renderCell: (d: any) => {
                return (
                    <Typography variant={'body2'} fontWeight='bold'>
                        {d.row.total}
                    </Typography>
                )
            },
        },
        {
            field: 'active',
            headerName: 'Status',
            flex: 1,
            minWidth: 180,
            renderCell: (d: any) => {
                return (
                    <>
                        <Stack direction={'row'} spacing={2}>
                            {!d.row?.active
                                ? <Chip label="Inative" /> :
                                <Chip label="Active" color="success" />
                            }
                            {!d.row?.vote
                                ? <Chip label="No Vote" /> :
                                <Chip label="Vote" color="success" />
                            }
                        </Stack>
                    </>
                )
            },
        },
        {
            field: 'end_date',
            headerName: 'ExpireAt',
            flex: 1,
            minWidth: 150,
            renderCell: (d: any) => {
                return (
                    <Typography variant={'body2'} color={'primary_light.main'}>
                        {moment(d.row.end_date).format('DD/MM/YYYY')}
                    </Typography>
                )
            },
        },
        {
            field: 'created_at',
            headerName: 'CreatedAt',
            flex: 1,
            minWidth: 150,
            renderCell: (d: any) => {
                return (
                    <Typography variant={'body2'} color={'primary_light.main'}>
                        {moment(d.row.created_at).format('DD/MM/YYYY')}
                    </Typography>
                )
            },
        },
        {
            field: '',
            sortable: false,
            width: 100,
            renderCell: (d: any) => {
                return (
                    <Stack direction={'row'} spacing={0.5}>
                        <IconButton
                          onClick={() => showModal(
                              UpdateStepFormDialog,
                              {title: 'Update Step' , content : d.row  })}
                        >
                            <EditOutlinedIcon/>
                        </IconButton>
                        <IconButton
                          onClick={() => showModal(
                                DeleteStepFormDialog,
                                {
                                    title: 'Delete Step' ,
                                    content : d.row,
                                    description : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua' })}
                        >
                            <DeleteOutlineRoundedIcon color={'error'}/>
                        </IconButton>
                    </Stack>
                )
            },
        },
    ];


    return (
        <>
            <Stack direction='row' justifyContent={'space-between'} alignItems={'center'}>
                <Breadcrumds name={'Steps Challenge'} link={'#'} subname={'steps'}/>
                <Button className={'app-btn'}
                  onClick={() => showModal(CreateStepFormDialog, {title: 'Create Step'})}
                  startIcon={<AddIcon/>}
                  sx={{
                            padding: '0.9rem 2rem',
                            marginTop: '20px',
                        }} variant="contained">New Step</Button>
            </Stack>
            <Stack sx={{
                minHeight: '60vh',
                width: '100%',
                border: '2px solid #ECEEF7',
                backgroundColor: '#FFFFFF',
                borderRadius: '6px',
                marginTop: '5%',
            }}>
                <DataGridApp
                  title={''}
                  height={'100%'}
                  data={data ?? []}
                  dataSorting={[]}
                  columns={columns}
                  hasSearchBar={true}
                  loading={isLoading}
                />
            </Stack>
        </>
    );
}

// Default export
export default Steps;
