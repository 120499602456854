import { Stack, Typography} from '@mui/material';
import Breadcrumds from '../../components/breadcrumds';
import React from 'react';
import CardFinance from '../../components/home/card-finance';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import DangerousOutlinedIcon from '@mui/icons-material/DangerousOutlined';
import DataGridApp from '../../components/customDataGrid';
import Chip from '@mui/material/Chip';
import moment from 'moment/moment';
import * as serviceIPC from '../../services/service-ipc';
import configuration from '../../config/ipc-config';
import CustomUseFetch from '../../hooks/CustomUseFetch';

function Finances() {
    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            renderCell: (d: any) => {
                return (
                    <>
                        <Stack paddingLeft={'15px'}>
                            <Typography variant={'body2'} color='primary' fontWeight='bold'>
                                {d.row.id}
                            </Typography>
                        </Stack>
                    </>
                )
            },
        },
        {
            field: 'phone',
            headerName: 'Phone Number',
            flex: 1,
            minWidth: 180,
            renderCell: (d: any) => {
                return (
                    <Typography variant={'body2'} color='primary' fontWeight='bold'>
                        {d.row.pay_number ?? 'ADMIN'}
                    </Typography>
                )
            },
        },
        {
            field: 'price',
            headerName: 'Price',
            flex: 1,
            minWidth: 150,
            renderCell: (d: any) => {
                return (
                    <Typography variant={'body2'} fontWeight='bold'>
                        { parseInt(d.row.price)} XAF
                    </Typography>
                )
            },
        },
        {
            field: 'provider',
            headerName: 'Method',
            flex: 1,
            minWidth: 150,
            renderCell: (d: any) => {
                return (
                    <Typography variant={'body2'} fontWeight='bold'>
                        {d.row.pay_method.toUpperCase()}
                    </Typography>
                )
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            minWidth: 180,
            renderCell: (d: any) => {
                return (
                    <>
                        {
                            <Chip label={d.row.status} color={d.row.status === 'PENDING' ? 'warning' : d.row.status === 'FAIL' ? 'error' : 'success' } />
                        }
                    </>
                )
            },
        },
        {
            field: 'created_at',
            headerName: 'CreatedAt',
            flex: 1,
            minWidth: 150,
            renderCell: (d: any) => {
                return (
                    <Typography variant={'body2'} color={'primary_light.main'}>
                        {moment(d.row.created_at).format('DD/MM/YYYY')}
                    </Typography>
                )
            },
        },
    ];

    const transactionAPI = new serviceIPC.TransactionsApi(configuration);
    const {data  } = CustomUseFetch(()=>transactionAPI.transactionControllerGetStats() , 'StatsTransactions');
    const {data : dataTransaction , isLoading } = CustomUseFetch(()=>transactionAPI.transactionControllerListCandidateProfile(0,0) , 'ListTransaction');

    return (
        <>
            <Stack>
                <Breadcrumds name={'Finances'} link={'#'} subname={'finances'}/>
            </Stack>
            <Stack spacing={3} justifyContent={'space-around'} direction={'row'} sx={{marginTop : '40px'}}>
                <CardFinance title1={'Success'} title2={data?.success ?? 0} title4={''} icon={<DoneAllIcon sx={{fontSize : '30px' , color : '#4a9863'}}/>} link={'#'}/>
                <CardFinance title1={'Pending'} title2={data?.pending ?? 0} title4={''} icon={<QueryBuilderIcon sx={{fontSize : '30px' , color : '#ab6905'}}/>} link={'#'}/>
                <CardFinance title1={'Fail '} title2={data?.fail ?? 0} title4={''} icon={<DangerousOutlinedIcon sx={{fontSize : '30px' , color : '#5b061d'}}/>} link={'#'}/>
            </Stack>
            <Stack sx={{
                minHeight : '65vh',
                width : '100%',
                border : '2px solid #ECEEF7',
                backgroundColor : '#FFFFFF',
                borderRadius : '6px',
                marginTop : '5%',
            }}>
                <DataGridApp
                  title={''}
                  height={'100%'}
                  data={dataTransaction ?? []}
                  dataSorting={[]}
                  columns={columns}
                  hasSearchBar={true}
                  loading={isLoading}
                />
            </Stack>
        </>
    );
}

// Default export
export default Finances;
