import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LoginForm from '../../components/forms/login-form';
import fd from './../../assets/images/fd03.jpg'

const defaultTheme = createTheme();
export default function LoginPage() {

    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid
                  item
                  xs={false}
                  sm={4}
                  md={7}
                  sx={{
                        backgroundImage: `url(${fd})`,
                        backgroundColor : 'black',
                        backgroundRepeat: 'no-repeat',
                        // backgroundColor: (t) =>
                        //     t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box  sx={{
                        // my: 8,
                        // mx: 4,
                        display: 'flex',
                        padding : '0 15%',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent : 'center',
                        height : '100%',
                    }}>
                        <LoginForm/>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}