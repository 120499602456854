import React from 'react';
import Box from '@mui/material/Box';
import { Stack } from '@mui/material';
import styles from './index.module.scss';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import {useNavigate} from 'react-router-dom';

export function Card(props : {title1 : string , title2 : string , title4 : string , icon : any , link : string}) {
    const navigation = useNavigate();

    return (
        <>
                <Box className={styles.boxCard}   onClick={() => {navigation(props.link)}}>
                    <Stack spacing={3} direction={'column'} justifyContent={'space-around'}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <h1 className={styles.title}>{props.title1}</h1>
                            {props.icon}
                        </Stack>
                        <h2 className={styles.title2}>{props.title2}</h2>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <h3 className={styles.title3}>{props.title4}</h3>
                            <NavigateNextOutlinedIcon sx={{fontSize : '30px' , color : '#057fab'}} className={styles.icon}/>
                        </Stack>
                    </Stack>
                </Box>
        </>
    );
}

export default Card;